import { useState } from "react";
import { Button, IconButton, TableCell, TableHead, TableRow, TableBody, Table, Paper, TableContainer } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const RoutineEntry = ({skillsList, routine, setEditing, duplicate, remove}) => {

    const [state, setState] = useState(false);

    var thisRoutine = {
        ...routine,
        skills: routine.routine.skills.map((skill) => {
            return skillsList.filter((value) => value.id === skill)[0];
        })
    };

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3 style={{ padding: '0 40px' }}>{routine.name}<span style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        marginLeft: '10px',
                        borderRadius: '50%',
                        backgroundColor: routine.status === 'active' ? '#008000' : '#F50213',
                }}></span></h3>

            <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={() => (remove(routine.id))}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>
            <IconButton style={{position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing('trampoline', routine)}>
                <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton style={{position: 'absolute', top: '35px', right: '5px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => duplicate('trampoline', routine)}> 
                <ContentCopyIcon fontSize="inherit" />
            </IconButton>

            <div style={{ textAlign: 'center', maxWidth: '400px' }}>
                <p style={{ fontWeight: '600', margin: '10px 0' }}>
                    Difficulty: <span style={{ fontWeight: 'bold' }}>{routine.routine.difficulty}</span>,
                    From 2025: <span style={{ fontWeight: 'bold' }}>{routine.routine.new_difficulty}</span>
                </p>
                
                {state && (
                    <>
                        <TableContainer component={Paper}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Skill</TableCell>
                                <TableCell>Difficulty</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {thisRoutine.skills.map((skill, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{skill.name}</TableCell>
                                    <TableCell>{skill.difficulty}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </div>

            <Button style={{fontSize: '14px', color: '#000', backgroundColor: 'transparent'}} onClick={() => setState(!state)}>
                {!state ? 'Show more' : 'Show less'}
            </Button>

        </div>
    )

}

export default RoutineEntry;