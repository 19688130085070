import { useEffect, useState } from "react";
import axios from "axios";
import { FormControl, InputLabel, Select, MenuItem, ToggleButton, ToggleButtonGroup, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import ErrorMessage from "../../../components/ErrorMessage.js";

const DrillInsights = ({ userId, discipline, stats, userDrills }) => {
    
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [ddOption, setDdOption] = useState(userDrills[0]?.drill.drill_id);
    const [timePeriod, setTimePeriod] = useState('all');
    const [drillStats, setDrillStats] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState("attempts");
    const [isError, setIsError] = useState(false);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        return `${day}/${month}`;
    };

    useEffect(() => {
        if (selectedObjective) {
            if (timePeriod === 'all') {
                setDrillStats([stats.find(stat => stat.id === selectedObjective.id)]);
            } else {
                axios.get(`${process.env.REACT_APP_API_HOST}/api/insights/drills/${discipline}/${userId}/${selectedObjective?.id}?period=${timePeriod}`)
                .then(response => {
                    const data = response.data;
                    if (data !== null && data.length > 0) {
                        setDrillStats(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setIsError(true);
                });
            }   
        }
        
    }, [userId, discipline, timePeriod, selectedObjective, stats])

    const handleObjectiveChange = (event) => {
        setDdOption(event.target.value);
        const selectedId = event.target.value;
        const objective = stats.find(obj => obj.id === selectedId);
        setSelectedObjective(objective || null);
    };

    const attributes = [
        {header: '# Attempts', field: 'attempts'},
        {header: 'Completion Rate', field: 'completion_rate'},
        {header: 'Skill Success Rate', field: 'skill_completion_rate'},
        ...(discipline === 'trampoline' ? [
            {header: 'Avg. ToF', field: 'average_tof'},
            {header: 'Avg. Stopwatch', field: 'average_stopwatch'},
            {header: 'Avg. HD', field: 'average_hd'},
        ] : [
            {header: 'Avg. Landing Pen', field: 'average_landing'},
        ]),
    ];

    const drillDates = [...new Set(drillStats?.map(drill => drill.period))];

    const columns = [
        { field: 'stat', headerName: 'Statistic', width: 120 },
        ...drillDates?.map(date => {
            // Find the corresponding drill object for this date
            const drill = drillStats.find(drill => drill.period === date);
    
            return {
                field: date,
                headerName: timePeriod === 'week' 
                ? `${formatDate(drill?.period_start)} - ${formatDate(drill?.period_end)}` 
                : date,
                width: 140,
                renderCell: params => {
                    if (params.id === 'completion_rate' || params.row.id === 'skill_completion_rate') {
                        return `${params.value || 0}%`;
                    } else if (params.row.id === 'average_landing') {
                        return params.value === 999 ? 0 : params.value;
                    } else if (params.row.id === 'average_tof' || params.row.id === 'average_stopwatch') {
                        return params.value && `${params.value}s`;
                    }
                    return params.value;
                },
            };
        }),
    ];

    const rows = attributes.map(attribute => ({
        id: attribute.field,
        stat: attribute.header,
        ...drillDates?.reduce((acc, date) => {
            const drill = drillStats?.find(s => s.period === date);
            acc[date] = drill ? drill[attribute.field] : 'N/A';
            return acc;
        }, {}),
    }));

    const graphData = drillDates.map(date => ({
        name: date,
        value: rows.find(row => row.id === selectedAttribute)[date],
    }))?.filter(attr => attr.value !== 0);

    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    return (
        <>
            <form className="new-entry">
                <FormControl fullWidth style={{ margin: "10px 0" }}>
                    <InputLabel id="objective-label">Drill</InputLabel>
                    <Select
                        labelId="objective-label"
                        label="Drill"
                        variant="outlined"
                        value={ddOption || ''}
                        onChange={handleObjectiveChange}
                    >

                        {userDrills.map((obj, index) => (
                            <MenuItem key={index} value={obj.drill.drill_id}>
                                {obj.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <ToggleButtonGroup
                    fullWidth
                    style={{marginBottom: '10px'}}
                    color="primary"
                    value={timePeriod}
                    exclusive
                    onChange={e => setTimePeriod(e.target.value)}
                    aria-label="By week or month"
                >
                    <ToggleButton color="primary" value={'all'} aria-label="all">All Time</ToggleButton>
                    <ToggleButton color="primary" value={'month'} aria-label="month">By Month</ToggleButton>
                    <ToggleButton color="primary" value={'week'} aria-label="week">By Week</ToggleButton>
                </ToggleButtonGroup>

                {/* Display Stats Only If selectedObjective Exists */}
                {selectedObjective ? (
                    <>                        
                        <Box sx={{ height: '380px', width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                hideFooter={true}
                            />
                        </Box>

                        {drillDates.length > 1 && <>
                            <FormControl fullWidth style={{ margin: "16px 0" }}>
                                <InputLabel>Graph Attribute</InputLabel>
                                <Select
                                value={selectedAttribute}
                                onChange={e => setSelectedAttribute(e.target.value)}
                                >
                                {rows.map(row => (
                                    <MenuItem key={row.id} value={row.id}>
                                    {row.stat}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>

                            <LineChart
                                width={330}
                                height={400}
                                data={graphData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </>}
                    </>
                ) : (
                    <p style={{color: 'var(--site-light-text-color)'}}>No statistics available for the selected objective.</p>
                )}
            </form>
        </>
    );
};

export default DrillInsights;
