
const ResourceEntry = ({ file }) => {

    return (
        <div className='entry' style={{cursor: 'pointer', position: 'relative', borderRadius: '10px', padding: '5px', margin: '5px 0'}} >
            <a href={file.url} target="_blank" rel="noopener noreferrer" style={{color: '#000', width: '100%', margin: '0 10px', textAlign: 'center'}}>
                {file.filename.split('/')[1].split('.')[0]}
            </a>
            <p>
                Category: {file.type} 
                {file.type === 'competition' &&
                    ' | ' + file.series
                }
            </p>

        </div>
    );
};

export default ResourceEntry;