import { useEffect, useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import LoadingMessage from '../../../../components/LoadingMessage.js';
import DeleteConfirmationBox from '../../../../components/DeleteConfirmationBox.js';
import ConditioningSessionEntry from './ConditioningSessionEntry';
import AddConditioningSession from './AddConditioningSession';
import ErrorMessage from "../../../../components/ErrorMessage.js";

const ConditioningSessions = ({ user }) => {

    const userId = user.userId;
    const [sessions, setSessions] = useState([]);
    const [state, setState] = useState('loading');
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);

    const emptySession = {
        user_id: userId,
        title: "",
        start_time: new Date().toISOString()
    }

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
      }, []);

    useEffect(() => {
        if (isTokenReady) {
            axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setSessions(data);
                }
                setState('view');
            })
            .catch(error => {
                console.error('Error fetching user conditioning sessions:', error);
                setIsError(true);
            });
        }
    }, [userId, isTokenReady]);

    const handleNewSession = (newSession) => {

        axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/sessions', newSession, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            const { last_id } = response.data;
            let newEntryWithId = {...newSession, id: last_id};
            setSessions([newEntryWithId, ...sessions]);
            setState('view');
        })
        .catch(error => {
            console.error('Error posting new conditioning session:', error);
            setIsError(true);
        });  
    }

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const handleDelete = (session_id) => {
        axios.delete(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/${userId}/${session_id}`)
        .then(() => {
            setSessions(sessions.filter(item => item.id !== session_id));
            setConfirmModal(false);
            setIdToDelete(0);
        })
        .catch(error => {
            console.error('Error deleting conditioning session', error);
            setIsError(true);
        });
    }

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    }

    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;

    if (state === 'loading') {
        return <LoadingMessage title={''} />
    }

    return (
        <>
            {state === 'view' && <>
                <div className='entries-in-tabs'>
                    {sessions?.map((session) => (
                        <ConditioningSessionEntry key={session.id} session={session} remove={handleConfirmRemove} />
                    ))} 
                </div>
            </>}

            {state === 'entering' && 
                <AddConditioningSession sessionHolder={emptySession} handleSubmit={handleNewSession} handleCancel={cancelEntry} />
            }
            
            {(state === 'view') &&
                <Fab color="secondary" aria-label="add" onClick={() => { setState('entering')}} style={{ position: 'absolute', bottom: '20px', right: '0px' }}>
                    <AddIcon sx={{ fontSize: '3rem' }} />
                </Fab>
            }    

            {confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this session?"} handleConfirm={() => handleDelete(idToDelete)} handleCancel={cancelEntry}/>
            }
        </>
    )
}

export default ConditioningSessions;