
const SkillBrick = ({playSkill, handleFocus}) => {

    const bgColor = () => {
        if (playSkill.proficiency === 0) {
            return "#D2222D";
        }
        else if (playSkill.proficiency === 1) {
            return "#FFBF00";
        }
        else {
            return "#238823";
        }
    }

    return (
        <div style={{border: '2px solid black', borderRadius: '10px', backgroundColor: bgColor(), margin: '5px 0 5px 0'}} onClick={() => {handleFocus(playSkill)}}>
            <h2 style={{padding: '10px', lineHeight: '36px', color: '#000'}}>{playSkill.name}</h2>
        </div>
    );
};

export default SkillBrick;