import { useState } from "react";
import { IconButton } from "@mui/material";
import VideocamIcon from '@mui/icons-material/Videocam';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IOSSwitch from "./Switch";


const CustomEntry = ({ type, item, handleSwitch, handleRenaming, handleDelete }) => {
    const nameField = (type === 'exercises') ? 'name' : 'title';
    const [status, setStatus] = useState(item.status);

    const statusChangeHandler = (e) => {
        const newStatus = e.target.checked ? 'active' : 'inactive';
        setStatus(newStatus);
        handleSwitch(type, item, newStatus);
    }

    const listStyle = {padding: '10px', lineHeight: '36px', color: '#000', width: '80%'};
    if (!item.url && type === 'exercises' && item.type === 'standard') {
        listStyle['width'] = '100%';
    }

    return (
        <div style={{border: '2px solid var(--site-light-background-color)', borderRadius: '10px', backgroundColor: 'var(--site-light-background-color)', margin: '5px 0 5px 0', display: 'flex', alignItems: 'center', gap: '5px'}}>
            <h3 style={listStyle}>{item[nameField]}</h3>
            {item.url &&
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <IconButton style={{padding: '0', fontSize: "40px", color: "var(--site-background-color)"}} 
                        aria-label="back" size="large" alt='back'
                    >
                        <VideocamIcon fontSize="inherit" />
                    </IconButton>
                </a>
            }
            {!(type === 'exercises' && item.type === 'standard') && <>
                <IOSSwitch checked={status === 'active'} onChange={statusChangeHandler} />
                <IconButton style={{padding: '0', fontSize: "25px", color: "var(--site-background-color)", marginRight: type === 'exercises' ? '5px' : '0'}} aria-label="edit" size="large" alt='edit' onClick={() => handleRenaming(type, item)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton style={{padding: '0', fontSize: "25px", color: "var(--site-background-color)", marginRight: '5px'}} aria-label="delete" size="large" alt='delete' onClick={() => handleDelete(type, item)} >
                    <DeleteIcon color="black" fontSize="inherit" />
                </IconButton>
            </>}
        </div>
    )
};

export default CustomEntry;