import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import DiaryIcon from '@mui/icons-material/EditNote';
import SettingsIcon from '@mui/icons-material/Settings';
import InsightsIcon from '@mui/icons-material/Insights';

function getPageIndex(route) {
  //console.log(route);
  switch (route) {
    case '/': return 0;
    case '/diary': return 1;
    case '/data': return 2;
    case '/settings': return 3;
    case 'Home': return 0;
    case 'Diary': return 1;
    case 'Insights': return 2;
    case 'Settings': return 3;
    default: return 0;
  }
}



const BottomNav = () => {
  const location = useLocation();
  const [value, setValue] = useState(() => getPageIndex(location.pathname));
  const navigate = useNavigate();

  return (
    <BottomNavigation sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'var(--site-light-background-color)' }}
      showLabels
      value={value}
      onChange={(e, newValue) => {
        setValue(getPageIndex(newValue));
      }}
    >
      <BottomNavigationAction label="Home" value={(value === 0 ? value : "Home")} onClick={() => navigate("/")} icon={<HomeIcon sx={{ fontSize: 20 }} />} />
      <BottomNavigationAction label="Diary" value={(value === 1 ? value : "Diary")} onClick={() => navigate("/diary")}  icon={<DiaryIcon sx={{ fontSize: 20 }} />} />
      <BottomNavigationAction label="Insights" value={(value === 2 ? value : "Insights")} onClick={() => navigate("/data")} icon={<InsightsIcon sx={{ fontSize: 20 }} />} />
      <BottomNavigationAction label="Settings" value={(value === 3 ? value : "Settings")} onClick={() => navigate("/settings")} icon={<SettingsIcon sx={{ fontSize: 20 }} />} />
    </BottomNavigation>
  )
};

export default BottomNav;