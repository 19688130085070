import { useState } from "react";
// import axios from "axios";
import { Tab, Tabs, Box } from "@mui/material";
import '../../style/Skills.css';
import { useData } from "../../DataContext";
import NewSkills from "./components/NewSkills";
import PlaySkills from "./components/PlaySkills";
import Header from "../../components/general/Header.tsx";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";

const Skills = ({user}) => {

    const { skillsList } = useData();
    const [value, setValue] = useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Header title={'Skills'} />    
                    
            <Box sx={{ width: '100%', borderRadius: '10px', borderBottom: 1, borderColor: 'divider', overflow:'hidden' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="New Skills" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(0)} />
                    <Tab label="Play Skills" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                </Tabs>              
            </Box>
            
            <TabPanel value={value} index={0}>
                <NewSkills skills={skillsList?.filter(val => (val.id > 2))} user={user} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PlaySkills user={user} />
            </TabPanel>

        </>
    )

};

export default Skills;