import { useState } from "react";
import { TextField, Button } from "@mui/material";

const RenameConditioningItem = ({ type, item, names, handleSubmit, handleCancel }) => {
    
    const [name, setName] = useState(item[type === 'exercises' ? 'name' : 'title']);

    return (<>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}></div>
        
        <div className="form-wrapper" style={{backgroundColor: 'var(--site-light-background-color)', padding: '0 5px', border: "2px solid var(--site-light-background-color)", borderRadius: '15px', width: '100%', zIndex: '1000'}}>
            <h2>Rename</h2>
            <TextField fullWidth style={{marginTop: '15px'}}
                type="text"
                id="New Name"
                label="New Name"
                variant="outlined"
                value={name}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setName(e.target.value)}
            />

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={() => handleSubmit(type, {...item, [type === 'exercises' ? 'name' : 'title']: name})}
                    disabled={!name?.length || names.includes(name.toLowerCase())}
                    variant="contained"
                >
                    Save
                </Button>

                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </div>
    </>)
}

export default RenameConditioningItem;