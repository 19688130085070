import { useNavigate } from 'react-router-dom';
import { IconButton } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';

const progressColors = {
    red: '#FF0000',
    amber: '#FFBF00',
    green: '#008000',
};

const GoalEntry = ({goal, remove}) => {

    const navigate = useNavigate();
    const dotColor = progressColors[goal.rag] || '#fff';

    const handleParentClick = () => {
        navigate("/goal", { state: { goal }});
    };

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        remove(goal.id);
    };

    return (
        <div className='entry' style={{cursor: 'pointer', position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}} onClick={handleParentClick}>
            
            <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={handleDeleteClick}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>

                <h3 style={{ padding: "0 15px"}}>{goal.title}</h3>
                <p>Target date: {new Date(goal.target_date).toLocaleDateString('en-GB')}</p>
                <p>Next review: {new Date(goal.next_review_date).toLocaleDateString('en-GB')}</p>
                {goal.rag.length !== 0 &&
                    <p>
                        Progress: <span style={{
                            display: 'inline-block',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: dotColor,
                    }}></span>
                  </p>
                }
        </div>
    )
}

export default GoalEntry;