import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

const DiaryEntry = ({sessionStatus, entry, index, remove, setEditing, addRep, duplicate, openReadOnly}) => {
    const [state, setState] = useState(false);

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3 style={{ padding: '0 40px' }}>{(index) + ', ' + entry.objective.type + ':'}</h3>
            {sessionStatus === 'active' ? <>
                <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={() => (remove(entry.id))}>
                    <DeleteIcon color="black" fontSize="inherit" />
                </IconButton>
                <IconButton style={{position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing(entry)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>

                {entry.objective.type !== 'new-skill' && <>
                    <IconButton style={{position: 'absolute', top: '35px', right: '5px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => duplicate(entry)}>
                        <ContentCopyIcon fontSize="inherit" />
                    </IconButton>

                    {/* Only show if already multiple reps. hide for new skills*/}
                    {(entry.repetitions > 1) &&
                        <IconButton style={{position: 'absolute', top: '60px', right: '5px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => addRep(entry)}>
                            <PlusOneIcon fontSize="inherit" />
                        </IconButton>
                    }
                </>}
            </> : <>
                <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0', fontSize: '25px'}} aria-label="open" alt='delete' onClick={() => setEditing(entry)}>
                    <SearchIcon color="black" fontSize="inherit" />
                </IconButton>
            </>}

            <p style={{ fontWeight: 'bold' }}>
                {(entry.repetitions > 1 ? (entry.repetitions + 'x ') : '') + entry.objective.name + (entry.objective.type === 'section' ? (', skills ' + entry.objective.parent_first_skill + ' to ' + entry.objective.parent_last_skill) : '')}
            </p>


            {state && <>
                {(entry.total_tof > 0) && <>
                    <p>{(entry.time_method === 'stopwatch' ? 'Stopwatch time: ' : 'Time of Flight: ') + entry.total_tof + 's'}</p>
                </>}
                {(entry.total_hd > 0) && <>
                    <p>{'HD: ' + entry.total_hd}</p>
                </>}
                {(entry.execution > 0) && <>
                    <p>{'Execution: ' + entry.execution}</p>
                </>}
                {(entry.landing_penalty != null && entry.landing_penalty !== 999) && <>
                    <p>{'Landing Deduction: ' + entry.landing_penalty}</p>
                </>}
                <p>{'Completed skills: ' + entry.total_completed_skills}</p>
                <p>{'Difficulty' + (entry.repetitions > 1 ? ' (each)' : '') + ': ' + entry.total_difficulty}</p>
                {(entry.feedback !== null && entry.feedback !== '') && <>
                    <p style={{ fontWeight: '600' }}>Feedback</p>
                    <p>{entry.feedback}</p>
                </>}
                {(entry.self_reflection !== null && entry.self_reflection !== '') && <>
                    <p style={{ fontWeight: '600' }}>Self Reflection</p>
                    <p>{entry.self_reflection}</p>
                </>}
            </>}

            <Button style={{fontSize: '1.4rem', color: '#000', backgroundColor: 'transparent'}} onClick={() => setState(!state)}>
                {!state ? 'Show more' : 'Show less'}
            </Button>
            
        </div>
    )
}

export default DiaryEntry;