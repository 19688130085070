import { useEffect, useState } from "react";
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Tab, Tabs, Box, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ResultsEntry from "./results/TrampolineResultsEntry.js";
import AddResult from "./results/AddTrampolineResult.js";
import DmtResultsEntry from "./results/DmtResultsEntry.js";
import AddDmtResult from "./results/AddDmtResult.js";
import TumblingResultsEntry from "./results/TumblingResultsEntry.js";
import AddTumblingResult from "./results/AddTumblingResult.js";
import SynchroResultsEntry from "./results/SynchroResultsEntry.js";
import AddSynchroResult from "./results/AddSynchroResult.js";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox.js";
import Header from "../../components/general/Header.tsx";
import ErrorMessage from "../../components/ErrorMessage.js";
  
const Events = ({ user }) => {

    const userId = user?.userId ? user.userId : "11111-11111-11111-11111";

    const [value, setValue] = useState(0);
    const [state, setState] = useState('view');
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [trampResults, setTrampResults] = useState([]);
    const [dmtResults, setDmtResults] = useState([]);
    const [tumblingResults, setTumblingResults] = useState([]);
    const [synchroResults, setSynchroResults] = useState([]);
    const [allRoutines, setAllRoutines] = useState([]);
    const [isError, setIsError] = useState(false);
    

    const blankResult = {
        user_id: userId,
        event: null,
        result_date: new Date().toISOString(),
        routine: {
            id: '',
            name: ''
        },
        discipline: 'trampoline',
        routine_type: 'vol',
        score_input_type: 'parts',
        skills_completed: 10,
        total_score: 0,
        execution: 0,
        hd: 0,
        tof: 0,
        difficulty: 0,
        penalty: 0,
        position: 0,
        comment: null
    };

    const blankDmtResult = {
        user_id: userId,
        event: null,
        result_date: new Date().toISOString(),
        pass: {
            id: '',
            name: ''
        },
        discipline: 'dmt',
        score_input_type: 'parts',
        skills_completed: 2,
        total_score: 0,
        execution: 0,
        difficulty: 0,
        penalty: 0,
        position: 0,
        comment: null
    };

    const blankTumblingResult = {
        user_id: userId,
        event: null,
        result_date: new Date().toISOString(),
        pass: {
            id: '',
            name: ''
        },
        discipline: 'tumbling',
        score_input_type: 'parts',
        skills_completed: 8,
        total_score: 0,
        execution: 0,
        difficulty: 0,
        penalty: 0,
        position: 0,
        comment: null
    };

    const blankSynchroResult = {
        user_id: userId,
        event: null,
        result_date: new Date().toISOString(),
        routine: {
            id: '',
            name: ''
        },
        discipline: 'synchro',
        score_input_type: 'parts',
        skills_completed: 10,
        total_score: 0,
        execution: 0,
        hd: 0,
        desynch: 0,
        difficulty: 0,
        penalty: 0,
        position: 0,
        comment: null
    };

    const [resultPlaceHolder, setResultPlaceHolder] = useState(blankResult);

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
      }, []);
    
    useEffect(() => {
        if (isTokenReady) {

            axios.get(process.env.REACT_APP_API_HOST + `/api/results/trampoline/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setTrampResults(data);
                }
            })
            .catch(error => {
                console.error('Error fetching trampoline results: ', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/results/dmt/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setDmtResults(data);
                }
            })
            .catch(error => {
                console.error('Error fetching dmt results: ', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/results/tumbling/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setTumblingResults(data);
                }
            })
            .catch(error => {
                console.error('Error fetching tumbling results: ', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/results/synchro/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setSynchroResults(data);
                }
            })
            .catch(error => {
                console.error('Error fetching synchro results: ', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/all-routines/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setAllRoutines(data);
                }
            })
            .catch(error => {
                console.error('Error fetching all routines: ', error);
                setIsError(true);
            });
        }

    }, [userId, isTokenReady])

    const handleNewEntry = (discipline, newEntry) => {
        
        if (state === 'entering' || state === 'entering-dmt' || state === 'entering-tumbling' || state === 'entering-synchro') {
            axios.post(process.env.REACT_APP_API_HOST + `/api/results/${discipline}`, newEntry, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                // Extract result ID from the response
                const { last_id } = response.data;
                let newEntryWithId = {...newEntry, id: last_id};
                switch (discipline){
                    case 'trampoline':
                        setTrampResults([newEntryWithId, ...trampResults]);
                        break;
                    
                    case 'dmt':
                        setDmtResults([newEntryWithId, ...dmtResults]);
                        break;
                    
                    case 'tumbling':
                        setTumblingResults([newEntryWithId, ...tumblingResults]);
                        break;
                        
                    case 'synchro':
                        setSynchroResults([newEntryWithId, ...synchroResults]);
                        break;

                    default:
                        console.log('Discipline not recognised');
                }
            })
            .catch(error => {
                console.error(`Error posting result entry details:`, error);
                setIsError(true);
            }); 
        } else {
            axios.put(process.env.REACT_APP_API_HOST + `/api/results/${discipline}`, newEntry, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                switch (discipline) {
                    case 'trampoline':
                        setTrampResults(trampResults.map(entry => {
                            if (entry.id === newEntry.id) {
                                return newEntry
                            }
                            return entry
                        }));
                        break;
                    
                    case 'dmt':
                        setDmtResults(dmtResults.map(entry => {
                            if (entry.id === newEntry.id) {
                                return newEntry
                            }
                            return entry
                        }));
                        break;

                    case 'tumbling':
                        setTumblingResults(tumblingResults.map(entry => {
                            if (entry.id === newEntry.id) {
                                return newEntry
                            }
                            return entry
                        }));
                        break;

                    case 'synchro':
                        setSynchroResults(synchroResults.map(entry => {
                            if (entry.id === newEntry.id) {
                                return newEntry
                            }
                            return entry
                        }));
                        break;

                    default:
                        console.log('Discipline not recognised');
                }               
            })
            .catch(error => {
                console.error(`Error updating result entry details:`, error);
                setIsError(true);
            }); 
        }
        setState('view');
    };
   
    const handleEditing = (discipline, entry) => {
        setResultPlaceHolder(entry);
        switch (discipline) {
            case 'trampoline':
                setState('editing');
                break;
            case 'dmt':
                setState('editing-dmt');
                break;
            case 'tumbling':
                setState('editing-tumbling');
                break;
            case 'synchro':
                setState('editing-synchro');
                break;
            default:
                console.log('Discipline not recognised');
        }
    };

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const removeEntry = (id) => { 
        let discipline = 'trampoline';
        switch (value) {
            case 1:
                discipline = 'dmt';
                break;
            case 2:
                discipline = 'tumbling';
                break;
            case 3:
                discipline = 'synchro';
                break;
            default:
                discipline = 'trampoline';
                break;
        }
        axios.delete(process.env.REACT_APP_API_HOST + `/api/results/${discipline}/${userId}/${id}`)
        .then(() => {
            switch (discipline) {
                case 'trampoline':
                    setTrampResults(trampResults.filter(item => item.id !== id));
                    break;
                case 'dmt':
                    setDmtResults(dmtResults.filter(item => item.id !== id));
                    break;
                case 'tumbling':
                    setTumblingResults(tumblingResults.filter(item => item.id !== id));
                    break;
                case 'synchro':
                    setSynchroResults(synchroResults.filter(item => item.id !== id));
                    break;
                default:
                    console.log('Discipline not recognised');
            }
        })
        .catch(error => {
            console.error(`Error deleting ${discipline} result`, error);
            setIsError(true);
        });
        setConfirmModal(false);
        setIdToDelete(0);
    };

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    };
    
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    
    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;

    return (
        <>
            <Header title={state === 'view' ? 'Results' : 'Record Result'} /> 

            {state === 'view' && <>           
                <Box sx={{ width: '100%', borderRadius: '10px', overflow:'hidden' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="results discipline">
                            <Tab sx={{width: '25%', fontSize: '11px', minWidth: '80px'}} label="Trampoline" {...a11yProps(0)} />
                            <Tab sx={{width: '25%', fontSize: '11px', minWidth: '80px'}} label="DMT" {...a11yProps(1)} />
                            <Tab sx={{width: '25%', fontSize: '11px', minWidth: '80px'}} label="Tumbling" {...a11yProps(2)} />
                            <Tab sx={{width: '25%', fontSize: '11px', minWidth: '80px'}} label="Synchro" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                </Box>
                <TabPanel value={value} index={0}>
                    {(trampResults?.length === 0) ? 
                        <p>No results found</p> :
                        <div className='entries-in-tabs'>
                            {trampResults?.map((entry, index) => (
                                <ResultsEntry 
                                    key={entry.id} 
                                    result={entry} 
                                    index={trampResults.length - index} 
                                    remove={handleConfirmRemove} 
                                    setEditing={handleEditing} 
                                />
                            ))}
                        </div>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {(dmtResults?.length === 0) ? 
                        <p>No results found</p> :
                        <div className='entries-in-tabs'>
                            {dmtResults?.map((entry, index) => (
                                <DmtResultsEntry 
                                    key={entry.id} 
                                    result={entry} 
                                    index={dmtResults.length - index} 
                                    remove={handleConfirmRemove} 
                                    setEditing={handleEditing} 
                                />
                            ))}
                        </div>
                    }
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {(tumblingResults?.length === 0) ? 
                        <p>No results found</p> :
                        <div className='entries-in-tabs'>
                            {tumblingResults?.map((entry, index) => (
                                <TumblingResultsEntry 
                                    key={entry.id} 
                                    result={entry} 
                                    index={tumblingResults.length - index} 
                                    remove={handleConfirmRemove} 
                                    setEditing={handleEditing} 
                                />
                            ))}
                        </div>
                    }
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {(synchroResults?.length === 0) ? 
                        <p>No results found</p> :
                        <div className='entries-in-tabs'>
                            {synchroResults?.map((entry, index) => (
                                <SynchroResultsEntry 
                                    key={entry.id} 
                                    result={entry} 
                                    index={synchroResults.length - index} 
                                    remove={handleConfirmRemove} 
                                    setEditing={handleEditing} 
                                />
                            ))}
                        </div>
                    }
                </TabPanel>
            </>}
            
            {(state === 'entering' || state === 'editing') &&
                <AddResult routines={allRoutines} resultHolder={resultPlaceHolder} handleNewResult={handleNewEntry} handleCancel={cancelEntry} />
            }

            {(state === 'entering-dmt' || state === 'editing-dmt') &&
                <AddDmtResult routines={allRoutines} resultHolder={resultPlaceHolder} handleNewResult={handleNewEntry} handleCancel={cancelEntry} />
            }

            {(state === 'entering-tumbling' || state === 'editing-tumbling') &&
                <AddTumblingResult routines={allRoutines} resultHolder={resultPlaceHolder} handleNewResult={handleNewEntry} handleCancel={cancelEntry} />
            }

            {(state === 'entering-synchro' || state === 'editing-synchro') &&
                <AddSynchroResult routines={allRoutines} resultHolder={resultPlaceHolder} handleNewResult={handleNewEntry} handleCancel={cancelEntry} />
            }

            {confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this result?"} handleConfirm={() => removeEntry(idToDelete)} handleCancel={cancelEntry}/>
            }

            {state === 'view' &&
                <Fab color="secondary" aria-label="add" 
                    onClick={() => {if (value === 0) {setResultPlaceHolder(blankResult); setState('entering')}
                                    else if (value === 1) {setResultPlaceHolder(blankDmtResult); setState('entering-dmt')}
                                    else if (value === 2) {setResultPlaceHolder(blankTumblingResult); setState('entering-tumbling')}
                                    else if (value === 3) {setResultPlaceHolder(blankSynchroResult); setState('entering-synchro')}
                                }} 
                    style={{ position: 'absolute', bottom: '20px', right: '0' }}
                >
                    <AddIcon sx={{ fontSize: '3rem' }} />
                </Fab>
            }
        </>
    );
};

export default Events;