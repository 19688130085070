import { Button } from "@mui/material";

const DeleteConfirmationBox = ({message, handleConfirm, handleCancel}) => {


    return (
        <div style={{backgroundColor: '#fff', border: "2px solid #545454", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000', padding: '10px'}}>
            <h2>Warning</h2>
            <p style={{margin: '10px 0'}}>{message}</p>
            <div className='bottom-buttons'>
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleConfirm}
                    variant="contained"
                >
                    Yes
                </Button>

                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    No
                </Button>
            </div>
        </div>
    )
}

export default DeleteConfirmationBox;