import { useState } from "react";
import { TextField, Button } from "@mui/material";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import RoundDownToFiveMinutes from '../../../../components/utils/RoundTimeToFiveMinutes';


const AddConditioningSession = ({sessionHolder, handleSubmit, handleCancel}) => {

    const [session, setSession] = useState(sessionHolder);
    const [startTime, setStartTime] = useState(dayjs(RoundDownToFiveMinutes(new Date())));

    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setSession({ ...session, [fieldName]: '' });
        } else {
            setSession({ ...session, [fieldName]: e.target.value });
        }
        // console.log(session);
    };

    const handleDateChange = (field, date) => {
        setSession({
            ...session,
            [field]: date
        });
    };

    const preSubmit = () => {
        setSession((prev) => {
            const newSession = {
                ...prev,
                start_time: dayjs(prev.start_time)
                            .hour(startTime.hour())
                            .minute(startTime.minute())
                            .second(0)
                            .millisecond(0)
                            .format('YYYY-MM-DDTHH:mm:ssZ')
            };

            handleSubmit(newSession);
            return newSession
        })
    }

    const inputStyle = {
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    };

    return (<>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}>
        </div>
        <div className="form-wrapper" style={{backgroundColor: 'var(--site-light-background-color)', padding: '0 5px', border: "2px solid var(--site-light-background-color)", borderRadius: '15px', width: '100%', zIndex: '1000'}}>
            <h2>New Conditoning Session</h2>

            <TextField fullWidth
                type="text"
                id="title"
                label="Title"
                variant="outlined"
                value={session.title || ''}
                onChange={(e) => handleFieldChange('title', e)}
            />

            <TextField fullWidth style={{marginTop: '15px'}}
                label="Start Date"
                type="date"
                value={session.start_time.split('T')[0] || null}
                onChange={(e) => handleDateChange('start_time', new Date(e.target.value).toISOString())}
                InputLabelProps={{ shrink: true }}
                InputProps={{ style: inputStyle }}  
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker 
                    label="Start time" 
                    value={startTime}
                    onChange={(time) => {setStartTime(time)}}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            InputProps: {
                            sx: {
                                fontSize: '14px', // Increase the font size for the input text
                                textAlign: 'center',
                            },
                            },
                            InputLabelProps: {
                                shrink: true,
                                sx: {
                                    fontSize: '12px', // Increase the font size for the label
                                },
                            },
                            sx: {
                                marginTop: '15px',   // Add margin-top of 10px
                            },
                        },
                    }}
                />
            </LocalizationProvider>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={session.title === null || session.title.length === 0}
                    onClick={preSubmit}
                    variant="contained"
                >
                    Save
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>     

        </div>
    </>)

};

export default AddConditioningSession;