import { useState } from "react";
import { TextField, Button, Box, TableCell, TableHead, TableRow, TableBody, Table, Paper, TableContainer } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
import AddConditioningExercise from "../standard/AddConditioningExercise.js";

const CreateConditioningCircuit = ({ names, exerciseList, submitHandler, handleCancel }) => {

    const [circuit, setCircuit] = useState({title: ''});
    const [exercises, setExercises] = useState([]);
    const [addingExercise, setAddingExercise] = useState(false);

    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setCircuit({ ...circuit, [fieldName]: '' });
        } else {
            setCircuit({ ...circuit, [fieldName]: e.target.value });
        }
    };

    const handleNewExercise = (newExercise) => {
        setExercises([...exercises, newExercise]);
        setAddingExercise(false);
    }

    const preSubmit = () => {
        setCircuit((prev) => {
            const newCircuit = {
                ...prev,
                exercises: exercises
            };

            submitHandler(newCircuit);
            return newCircuit
        });
    }

    if (exerciseList?.length === 0) {
        return (
            <p>Loading...</p>
        )
    }

    return (<>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}>
        </div>
    
        <div style={{backgroundColor: 'var(--site-light-background-color)', padding: '0 5px', border: "2px solid grey", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000'}}>
            <h2>Create New Circuit</h2>
            
            <TextField fullWidth style={{marginTop: '5px'}}
                type="text"
                id="title"
                label="Title"
                variant="outlined"
                value={circuit.title || ''}
                onChange={(e) => handleFieldChange('title', e)}
            />     

            <Box sx={{textAlign: 'center', margin: '10px 0', height: '33px', width: '100%'}}>
                <Button variant="contained" color="primary" style={{fontSize: '12px', color: 'var(--site-dark-text-color)'}} endIcon={<AddIcon />}  onClick={() => {setAddingExercise(true)}}>Add Exercise</Button>
            </Box>

            <TableContainer component={Paper} sx={{ marginTop: '20px', maxWidth: '400px', margin: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Exercise</TableCell>
                            <TableCell align="center">Reps</TableCell>
                            <TableCell align="center">Duration</TableCell>
                            <TableCell align="center">Weight</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exercises?.map((cEx, index) => {
                            const exercise = exerciseList.find(
                                ex => ex.id === cEx.exercise_id && ex.type === cEx.exercise_type
                            );
    
                            return (
                                <TableRow key={index}>
                                    <TableCell>{exercise ? exercise.name : "Unknown Exercise"}</TableCell>
                                    <TableCell align="center">{cEx.reps || '-'}</TableCell>
                                    <TableCell align="center">{cEx.duration ? `${cEx.duration} secs` : '-'}</TableCell>
                                    <TableCell align="center">{cEx.weight ? `${cEx.weight} kg` : '-'}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={!circuit?.title?.length || !exercises?.length || names.includes(circuit.title.toLowerCase())}
                    onClick={preSubmit}
                    variant="contained"
                >
                    Save
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div> 
        </div>

        {addingExercise && 
            <AddConditioningExercise exerciseList={exerciseList} handleSubmit={handleNewExercise} handleCancel={() => setAddingExercise(false)} />
        }
        
    </>)
};

export default CreateConditioningCircuit;