import { useState, useEffect } from "react";
import axios from "axios";
import { ToggleButton, ToggleButtonGroup, Box, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import ErrorMessage from "../../../components/ErrorMessage.js";

const SessionInsights = ({ userId, discipline }) => {
    const [insights, setInsights] = useState(null);
    const [loading, setLoading] = useState(true);
    const [timePeriod, setTimePeriod] = useState('month');
    const [selectedAttribute, setSelectedAttribute] = useState("total_skills");
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const endpoint = `/api/insights/sessions/${discipline}/${userId}?period=${timePeriod}`;
    
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(process.env.REACT_APP_API_HOST + endpoint);
                setInsights(response.data);
            } catch (error) {
                console.error('Error fetching insights:', error);
                setIsError(true);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [userId, discipline, timePeriod]);

    
    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    if (loading) {
        return <div>Loading...</div>;
    }

    // const columns = [
    //     { field: 'date', headerName: 'Date', width: 100 },
    //     { field: 'totalHours', headerName: 'Hours Trained', width: 120 },
    //     { field: 'totalExercises', headerName: '# Attempts', width: 120 },
    //     { field: 'totalSkills', headerName: '# Skills', width: 120 },
    //     { field: 'completionRate', headerName: 'Completion Rate', width: 120 },
    //     { field: 'skillCompletionRate', headerName: 'Skill Completion Rate', width: 120 },
    //     { field: 'totalDifficulty', headerName: 'Difficulty', width: 120 },
    // ];
    
    // const rows = insights.map((item, index) => ({
    //     id: index + 1,
    //     date: item.period, 
    //     totalHours: `${Math.floor(item.total_hours/60)}:${item.total_hours % 60} hrs`,
    //     totalExercises: item.total_attempts,
    //     totalSkills: item.total_skills,
    //     completionRate: item.completion_rate,
    //     skillCompletionRate: item.skills_completion_rate,
    //     totalDifficulty: item.total_difficulty,
    // }));

    const attributes = [
        {header: 'Hours trained', field: 'total_minutes'}, 
        {header: 'Total Efforts', field: 'total_attempts'}, 
        {header: 'Total Skills', field: 'total_skills'}, 
        {header: 'Total Difficulty', field: 'total_difficulty'}, 
        {header: 'Completion Rate', field: 'completion_rate'}, 
        {header: 'Skill Success Rate', field: 'skills_completion_rate'}
    ];
    const sessionDates = [...new Set(insights?.map(session => session.period))];

    const columns = [
        { field: 'stat', headerName: 'Statistic', width: 120 },
        ...sessionDates?.map(date => ({ 
            field: date, 
            headerName: date, 
            width: 120,
            renderCell: params => {
                if (params.id === 'completion_rate') {
                    return `${params.value || 0}%`;
                }
                else if (params.row.id === 'skills_completion_rate') {
                    return `${params.value || 0}%`;
                }
                else if (params.row.id === 'total_minutes') {
                    return `${Math.floor(params.value/60)}:${String(params.value % 60).padStart(2, '0')} hrs`;
                }
                return params.value;
            }
         })),
    ];

    const rows = attributes.map(attribute => ({
        id: attribute.field,
        stat: attribute.header,
        ...sessionDates?.reduce((acc, date) => {
            const session = insights?.find(s => s.period === date);
            acc[date] = session ? session[attribute.field] : 'N/A';
            return acc;
        }, {}),
    }));

    const graphData = sessionDates.map(date => ({
        name: date,
        value: rows.find(row => row.id === selectedAttribute)[date],
      })).filter(attr => attr.value !== 0);

    return (
    <form className="new-entry">

        <ToggleButtonGroup
            fullWidth
            style={{marginBottom: '10px'}}
            color="primary"
            value={timePeriod}
            exclusive
            onChange={e => setTimePeriod(e.target.value)}
            aria-label="By week or month"
        >
            <ToggleButton color="primary" value={'week'} aria-label="week">By Week</ToggleButton>
            <ToggleButton color="primary" value={'month'} aria-label="month">By Month</ToggleButton>
        </ToggleButtonGroup>

        <Box sx={{ height: '380px', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                hideFooter={true}
            />
        </Box>
        <FormControl fullWidth style={{ margin: "16px 0" }}>
            <InputLabel>Graph Attribute</InputLabel>
            <Select
                value={selectedAttribute}
                onChange={e => setSelectedAttribute(e.target.value)}
            >
            {rows.map(row => (
                <MenuItem key={row.id} value={row.id}>
                {row.stat}
                </MenuItem>
            ))}
            </Select>
        </FormControl>

        <LineChart
            width={330}
            height={400}
            data={graphData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>

    </form>)
}

export default SessionInsights;