import { Button } from "@mui/material";
import { useState } from "react";

const NewSkillFocus = ({newSkill, handleUpdate, handleExit}) => {

    const [skillProgress, setSkillProgress] = useState(newSkill);

    const bgColor = () => {
        if (newSkill.proficiency === 0) {
            return "#D2222D";
        }
        else if (newSkill.proficiency === 1) {
            return "#FFBF00";
        }
        else {
            return "#238823";
        }
    }  

    return (
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}>
            
            <div style={{ width: 'calc(100% - 40px)', position: 'absolute', top: '5%', bottom: '0', margin:'auto 20px', height: 'fit-content'}}>
                <div style={{border: '3px solid black', borderRadius: '15px', backgroundColor: bgColor()}}>
                    <h2 style={{color: '#000'}}>{newSkill.name}</h2>
                    <div className="flex-buttons" style={{margin: '10px 10% 2%'}}>  
                        <p style={{ color: '#000'}}>In rig:</p>                  
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setSkillProgress({...skillProgress, in_rig: skillProgress.in_rig === 0 ? 0 : skillProgress.in_rig - 1})}>-</Button>
                        <p style={{ color: '#000'}}>{skillProgress.in_rig}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setSkillProgress({...skillProgress, in_rig: skillProgress.in_rig + 1})}>+</Button>
                    </div>
                    <div className="flex-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{ color: '#000'}}>To pit:</p>                   
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setSkillProgress({...skillProgress, to_pit: skillProgress.to_pit === 0 ? 0 : skillProgress.to_pit - 1})}>-</Button>
                        <p style={{ color: '#000'}}>{skillProgress.to_pit}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setSkillProgress({...skillProgress, to_pit: skillProgress.to_pit + 1})}>+</Button>
                    </div>
                    <div className="flex-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{ color: '#000'}}>Caught:</p>                   
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setSkillProgress({...skillProgress, mat_with_catch: skillProgress.mat_with_catch === 0 ? 0 : skillProgress.mat_with_catch - 1})}>-</Button>
                        <p style={{ color: '#000'}}>{skillProgress.mat_with_catch}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setSkillProgress({...skillProgress, mat_with_catch: skillProgress.mat_with_catch + 1})}>+</Button>
                    </div>
                    <div className="flex-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{ color: '#000'}}>To mat:</p>                   
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setSkillProgress({...skillProgress, to_mat: skillProgress.to_mat === 0 ? 0 : skillProgress.to_mat - 1})}>-</Button>
                        <p style={{ color: '#000'}}>{skillProgress.to_mat}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setSkillProgress({...skillProgress, to_mat: skillProgress.to_mat + 1})}>+</Button>
                    </div>
                    <div className="flex-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{ color: '#000'}}>To bed:</p>                   
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setSkillProgress({...skillProgress, to_bed: skillProgress.to_bed === 0 ? 0 : skillProgress.to_bed - 1})}>-</Button>
                        <p style={{ color: '#000'}}>{skillProgress.to_bed}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setSkillProgress({...skillProgress, to_bed: skillProgress.to_bed + 1})}>+</Button>
                    </div>
                    <div className="flex-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{ color: '#000'}}>In combo:</p>                   
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setSkillProgress({...skillProgress, in_combination: skillProgress.in_combination === 0 ? 0 : skillProgress.in_combination - 1})}>-</Button>
                        <p style={{ color: '#000'}}>{skillProgress.in_combination}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setSkillProgress({...skillProgress, in_combination: skillProgress.in_combination + 1})}>+</Button>
                    </div>
                    <div className="flex-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{ color: '#000'}}>In routine:</p>                   
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setSkillProgress({...skillProgress, in_routine: skillProgress.in_routine === 0 ? 0 : skillProgress.in_routine - 1})}>-</Button>
                        <p style={{ color: '#000'}}>{skillProgress.in_routine}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setSkillProgress({...skillProgress, in_routine: skillProgress.in_routine + 1})}>+</Button>
                    </div>
                    <div className="flex-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{ color: '#000'}}>Competed:</p>                   
                        <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setSkillProgress({...skillProgress, competed: skillProgress.competed === 0 ? 0 : skillProgress.competed - 1})}>-</Button>
                        <p style={{ color: '#000'}}>{skillProgress.competed}</p>
                        <Button variant="outlined" size="large" className="add-subtract-button"  onClick={() => setSkillProgress({...skillProgress, competed: skillProgress.competed + 1})}>+</Button>
                    </div>
                </div>
                <div className='flex-buttons'>
                    <Button
                        style={{margin: "10px 5px 5px", width: '100px'}}
                        onClick={() => handleUpdate(skillProgress)}
                        variant="contained"
                    >
                        Save
                    </Button>

                    <Button
                        style={{margin: "10px 5px 5px", width: '100px'}}
                        onClick={handleExit}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </div> 

                {/* <Box sx={{textAlign: 'center', height: '33px', width: '100%'}}>
                    <Button variant="contained" style={{width: '100%', fontSize: '12px', color: 'var(--site-dark-text-color)', backgroundColor: 'var(--site-primary-color)', margin: '5% 2% 0 0'}} onClick={() => handleUpdate(skillProgress)}>Log Progress</Button>
                    <Button variant="contained" style={{width: '100%', fontSize: '12px', color: 'var(--site-dark-text-color)', backgroundColor: 'var(--site-primary-color)', marginTop: '5%'}} onClick={handleExit}>Cancel</Button>
                </Box> */}
                
                {/* <div className="flex-buttons">
                    <div className="skill-bottom-button" style={{ backgroundColor: '#fff', width: '50%' }}>
                        <input id="log-progress" type="submit" value={'Log progress'} onClick={() => handleUpdate(skillProgress)} style={{color: 'var(--site-dark-text-color)'}} />
                    </div>
                    <div className="skill-bottom-button" style={{ backgroundColor: '#fff', width: '50%' }}>
                        <input id="cancel" type="button" value={'Cancel'} onClick={handleExit} style={{color: 'var(--site-dark-text-color)'}} />
                    </div>

                </div> */}

            </div>
            
        </div>
    );
};

export default NewSkillFocus;