import { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import Carousel from "./Carousel";
import MoodConf from "./MoodConfirmation";
import SessionDetails from "./SessionDetails";
import '../../../style/Moodscreen.css'
import ErrorMessage from "../../../components/ErrorMessage.js";

const CreateTrainingSession = ({userId, defaultSlide}) => {

    const [stage, setStage] = useState('mood');
    const [slideIdx, setSlideIdx] = useState(defaultSlide);
    const [isError, setIsError] = useState(false);

    const navigate = useNavigate();   

    const imgs = [
        '../img/mood_s/very_bad.png',
        '../img/mood_s/bad.png',
        '../img/mood_s/neutral.png',
        '../img/mood_s/good.png',
        '../img/mood_s/very_good.png',
    ];

    const handleRecordMood = (newIdx) => {
        setSlideIdx(newIdx);
        setStage('confirmMood');
    };

    const getCombinedDateTime = (date, time) => {
        const dateTime = dayjs(date)
          .hour(time.hour())
          .minute(time.minute())
          .second(0)
          .millisecond(0);
    
        return dateTime;
    };

    const handleConfirmMood = () => {
        setStage('sessionDetails');
    };

    const handleSessionDetails = (sessionFields) => {

        var initialSession = {
            user_id: userId,
            session_date: getCombinedDateTime(sessionFields.sessionDate, sessionFields.startTime).format('YYYY-MM-DDTHH:mm:ssZ'),
            discipline: sessionFields.discipline,
            session_aims: sessionFields.aims,
            status: 'active',
            start_mood: slideIdx
        };

        axios.post(process.env.REACT_APP_API_HOST + '/api/sessions', initialSession, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            const session = response.data;
            navigate("/diary-entry", {state: {session: session}});
        })
        .catch(error => {
            console.error('Error posting session details:', error);
            setIsError(true);
        });        
    };
    
    const handleClose = () => {
        setStage('mood');
    };
    
    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    if (stage === 'mood') {
        return <Carousel images={imgs} defaultSlide={slideIdx} onRecord={handleRecordMood} /> 
    }
    else if (stage === 'confirmMood') {
        return <MoodConf moodVal={slideIdx} handleConfirm={handleConfirmMood} handleClose={handleClose} />
    }
    else if (stage === 'sessionDetails') {
        return <SessionDetails handleSubmit={handleSessionDetails} handleClose={handleClose} />
    }
    
    return <></>
    

};

export default CreateTrainingSession;