import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth';
import axios from 'axios';


const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [skillsList, setSkillsList] = useState(null);
  const [dmtSkillsList, setDmtSkillsList] = useState(null);
  const [tumbleSkillsList, setTumbleSkillsList] = useState(null);
  const [isTokenReady, setIsTokenReady] = useState(false);

  useEffect(() => {
    const fetchJwtToken = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.idToken;
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;
        setIsTokenReady(true); 
      } catch (error) {
        console.error('Error fetching JWT token:', error);
        setIsTokenReady(true); 
      }
    };

    fetchJwtToken();
  }, []);

  useEffect(() => {
    if (isTokenReady) {
      
      axios.get(process.env.REACT_APP_API_HOST + '/api/skills/trampoline')
      .then(response => {
        const data = response.data;
        if (data !== null) {
          setSkillsList(data);
        }
      })
      .catch(error => console.error('Error fetching all trampoline skills:', error));

      axios.get(process.env.REACT_APP_API_HOST + '/api/skills/dmt')
      .then(response => {
        const data = response.data;
        if (data !== null) {
          setDmtSkillsList(data);
        }
      })
      .catch(error => console.error('Error fetching all trampoline skills:', error));

      axios.get(process.env.REACT_APP_API_HOST + '/api/skills/tumbling')
      .then(response => {
        const data = response.data;
        if (data !== null) {
          setTumbleSkillsList(data);
        }
      })
      .catch(error => console.error('Error fetching all trampoline skills:', error));
      
    }
  }, [isTokenReady]);

  return (
    <DataContext.Provider value={{ skillsList, dmtSkillsList, tumbleSkillsList }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
