import { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";

const DataFilterSelect = ({ currentFilters, filterChange, handleCancel}) => {

    const [filters, setFilters] = useState(currentFilters);

    const handleFiltersChange = (field, newVal) => {

        if (field === 'discipline') {
            setFilters({
                ...filters,
                [field]: newVal,
                objective: ''
            });
        } else {
            setFilters({
                ...filters,
                [field]: newVal,
                discipline: 'trampoline',
                objective: ''
            });
        }
        
    };

    return (
        <div className="form-wrapper">
            <form className="confirm-modal" style={{padding: '0 5px 10px'}}>
                <h2>Filters</h2>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="objective-type-label">Objective type</InputLabel>
                    <Select
                        labelId="objective-type-label"
                        label="Objective Type"
                        variant="outlined"
                        value={filters.objectiveType}
                        onChange={(e) => handleFiltersChange('objectiveType', e.target.value)}
                    >
                        <MenuItem value={'routines'}>Routines</MenuItem>
                        <MenuItem value={'drills'}>Drills</MenuItem>
                        <MenuItem value={'results'}>Results</MenuItem>
                        <MenuItem value={'sessions'}>Sessions</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="discipline-label">Discipline</InputLabel>
                    <Select
                        labelId="discipline-label"
                        label="discipline"
                        variant="outlined"
                        value={filters.discipline}
                        onChange={(e) => handleFiltersChange('discipline', e.target.value)}
                    >
                        <MenuItem value={'trampoline'}>Trampoline</MenuItem>
                        {filters.objectiveType !== 'drills' &&
                            <MenuItem value={'dmt'}>DMT</MenuItem>
                        }
                        <MenuItem value={'tumbling'}>Tumbling</MenuItem>
                        {filters.objectiveType === 'results' &&
                            <MenuItem value={'synchro'}>Synchro</MenuItem>
                        }
                    </Select>
                </FormControl>
                <div className='flex-buttons'>
                    <Button
                        style={{margin: "10px 5px 5px", width: '100px'}}
                        onClick={() => filterChange(filters)}
                        variant="contained"
                    >
                        Save filter
                    </Button>

                    <Button
                        style={{margin: "10px 5px 5px", width: '100px'}}
                        onClick={handleCancel}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </div>    
            </form>
            
        </div>
    );
};

export default DataFilterSelect;