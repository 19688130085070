import { useState, useEffect } from "react";
import axios from "axios";
import { fetchAuthSession } from '@aws-amplify/auth';
import { Fab, Box, Tabs, Tab, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddDrill from "./components/AddDrill";
import AddTumblingDrill from "./components/AddTumblingDrill.js";
import DrillEntry from "./components/DrillEntry";
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox";
import { useData } from "../../DataContext";
import LoadingMessage from "../../components/LoadingMessage";
import Header from "../../components/general/Header.tsx";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";
import ErrorMessage from "../../components/ErrorMessage.js";

const Drills = ({ user }) => {

    const userId = user.userId;

    const blankDrill = {
        user_id: userId,
        type: 'trampoline',
        name: '',
        status: 'active',
        drill: {
            drill_id: '1-4-1-1-1-1',
            repetitions: 1,
            length: 4,
            skills: [1,1,1,1]
        },
        difficulty: 0     
    };

    const blankTumbleDrill = {
        user_id: userId,
        type: 'tumbling',
        name: '',
        status: 'active',
        drill: {
            drill_id: '1-3-1-3-5',
            repetitions: 1,
            length: 3,
            skills: [1,3,5]
        },
        difficulty: 0.7
    };

    const [state, setState] = useState('loading');
    const [tabValue, setTabValue] = useState(0);
    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [drillPlaceholder, setDrillPlaceholder] = useState(blankDrill);
    const [drills, setDrills] = useState([]);
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);
    const [trampolineDrills, setTrampolineDrills] = useState([]);
    const [tumblingDrills, setTumblingDrills] = useState([]);
    
    
    const { skillsList, tumbleSkillsList } = useData();

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true); 
          }
        };
    
        fetchJwtToken();
      }, []);

    useEffect(() => {
        if (isTokenReady) {
            axios.get(process.env.REACT_APP_API_HOST + `/api/user-drills/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setDrills(data);
                }
                setState('view');
            })
            .catch(error => {
                console.error('Error fetching user drills:', error);
                setIsError(true);
            });
        }
    }, [userId, isTokenReady]); 

    useEffect(() => {
        const trampoline = drills
          .filter(drill => drill.type === 'trampoline')
          .map(drill => drill.name.toLowerCase());
        const tumbling = drills
          .filter(drill => drill.type === 'tumbling')
          .map(drill => drill.name.toLowerCase());
    
        setTrampolineDrills(trampoline);
        setTumblingDrills(tumbling);
      }, [drills]);

    const handleNewDrill = (newDrill) => {

        if (state === 'addDrill' || state === 'addTumbleDrill' || state === 'view') {
            axios.post(process.env.REACT_APP_API_HOST + '/api/user-drills', newDrill, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setDrills([newDrill, ...drills]);
                setState('view');
            })
            .catch(error => {
                console.error('Error posting trampoline drill details:', error);
                setIsError(true);
            }); 
        } else {
            axios.patch(process.env.REACT_APP_API_HOST + '/api/user-drills', newDrill, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setDrills(drills.map(drill => {
                    if (drill.drill.drill_id === newDrill.drill.drill_id) {
                        return newDrill;
                    }
                    return drill;
                }));
                setState('view');
            })
            .catch(error => {
                console.error('Error patching drill details:', error);
                setIsError(true);
            }); 
        }
    };

    const handleEditing = (drill) => {
        setDrillPlaceholder(drill);

        setState(drill.type === 'trampoline' ? 'editDrill' : 'editTumbleDrill');
    }

    const handleDuplicate = (drill) => {
        console.log()
        const newRoutine = {...drill, name: drill.name + ' (1)'};
        switch (drill.type) {
            case 'trampoline':
                setDrillPlaceholder(newRoutine);
                setState('addDrill');
                break;
            case 'tumbling':
                setDrillPlaceholder(newRoutine);
                setState('addTumbleDrill');
                break;
            default:
                console.log('Discipline not recognised');
        };
    }

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
    };

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const removeEntry = (id) => { 
        axios.delete(process.env.REACT_APP_API_HOST + `/api/user-drills/${userId}/${id}`)
        .then(() => {
            setDrills(drills.filter(item => item.drill.drill_id !== id));
        })
        .catch(error => {
            console.error('Error deleting user drill', error);
            setIsError(true);
        });
        setConfirmModal(false);
        setIdToDelete(0);
    };

    const handleAddPressed = () => {
        setDrillPlaceholder(tabValue === 0 ? blankDrill : blankTumbleDrill);
        setState(tabValue === 0 ? 'addDrill' : 'addTumbleDrill');
    }

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };
    
    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;
    
    if (!skillsList?.length || !tumbleSkillsList?.length || state === 'loading') {
        return <LoadingMessage title={'Drills'} />
    }

    return (
        <>
            {state !== 'view' &&
                <Box sx={{ fontSize: '40px', textAlign: 'center', height: '60px', position: 'fixed', top: '20px', left: '50%', transform: 'translateX(-50%)', width: 'calc(100% - 40px)' }}>
                    <IconButton style={{position: 'absolute', top: '13px', left: '0', padding: '0', fontSize: "40px", color: "var(--site-light-text-color)"}} 
                        aria-label="back" size="large" alt='back' 
                        onClick={cancelEntry}
                    >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            }
            <Header title={state === 'view' ? 'Drills' : '  Add Drill  '} />

            <Box sx={{ width: '100%', backgroundColor: 'var(--site-light-background-color)', borderRadius: '10px', borderBottom: 1, borderColor: 'divider', overflow:'hidden' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example"
                    textColor="inherit"
                >
                    <Tab label="Trampoline" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(0)}  />
                    <Tab label="Tumbling" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                </Tabs>
            </Box>      

            <TabPanel value={tabValue} index={0}>
                {(state === 'view' && drills?.length > 0) &&
                    <div className='entries-in-tabs'>
                        {drills?.filter(drill => drill.type === 'trampoline').map((drill, index) => (
                            <DrillEntry key={index}  skillsList={skillsList} drill={drill} index={index} setEditing={handleEditing} duplicate={handleDuplicate} remove={handleConfirmRemove} />
                        ))}
                    </div>
                } 
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {(state === 'view' && drills?.length > 0) &&
                    <div className='entries-in-tabs'>
                        {drills?.filter(drill => drill.type === 'tumbling').map((drill, index) => (
                            <DrillEntry key={index}  skillsList={tumbleSkillsList} drill={drill} index={index} setEditing={handleEditing} duplicate={handleDuplicate} remove={handleConfirmRemove} />
                        ))}
                    </div>
                } 
            </TabPanel>

            {(state === 'addDrill' || state === 'editDrill') && 
                <AddDrill type={state} drillHolder={drillPlaceholder} skillsList={skillsList} names={trampolineDrills} handleNewDrill={handleNewDrill} handleCancel={cancelEntry} />
            }

            {(state === 'addTumbleDrill' || state === 'editTumbleDrill') && 
                <AddTumblingDrill type={state} drillHolder={drillPlaceholder} skillsList={tumbleSkillsList} names={tumblingDrills} handleNewDrill={handleNewDrill} handleCancel={cancelEntry} />
            }

            { confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this drill?"} handleConfirm={() => removeEntry(idToDelete)} handleCancel={cancelEntry}/>
            }

            {state === 'view' &&
                <Fab color="secondary" aria-label="add" onClick={handleAddPressed} style={{ position: 'absolute', bottom: '20px', right: '0px' }}>
                    <AddIcon sx={{ fontSize: '3rem' }} />
                </Fab>
            }
            
        </>
    )
}

export default Drills;