import { useState, useEffect } from 'react';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../../components/general/Header.tsx';
import ResultsInsight from './components/ResultsInsight.js';
import RoutineInsights from './components/RoutineInsights.js';
import DrillInsights from './components/DrillInsights.js';
import SessionInsights from './components/SessionInsights.js';
import DataFilterSelect from './components/DataFilterSelect.js';
import ErrorMessage from "../../components/ErrorMessage.js";

const Dashboard = ({ user }) => {
    
    const userId = user.userId !== null ? user.userId : "11111-11111-11111-11111";
    const [menuOpen, setMenuOpen] = useState(true);
    const [currentFilters, setCurrentFilters] = useState({
        discipline: 'trampoline',
        objectiveType: 'routines',
        objective: ''
    });
    const [objInsights, setObjInsights] = useState([]);
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [userDrills, setUserDrills] = useState([]);
    const [userRoutines, setUserRoutines] = useState([]);
    const [isError, setIsError] = useState(false);

    const handleFilterChange = (newFilters) => {
        setCurrentFilters(newFilters);
        setMenuOpen(false);
    };

    const handleCancel = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
    }, []);

    useEffect(() => {
        if (isTokenReady && (currentFilters.objectiveType === 'routines' || currentFilters.objectiveType === 'drills')) {
            const endpoint = currentFilters.objectiveType === 'routines' ? 'routines' : 'drills'  
            axios.get(`${process.env.REACT_APP_API_HOST}/api/insights/${endpoint}/${currentFilters.discipline}/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setObjInsights(data);
                }
            })
            .catch(error => {
                console.error('Error fetching routine insights:', error);
                setIsError(true);
            });

            if (currentFilters.objectiveType === 'routines') {
                axios.get(`${process.env.REACT_APP_API_HOST}/api/routines/${currentFilters.discipline}/${userId}`)
                .then(response => {
                    const data = response.data;
                    if (data !== null && data.length > 0) {
                        setUserRoutines(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setIsError(true);
                });
            }   

            if (currentFilters.objectiveType === 'drills') {
                axios.get(`${process.env.REACT_APP_API_HOST}/api/user-drills/${userId}`)
                .then(response => {
                    const data = response.data;
                    if (data !== null && data.length > 0) {
                        setUserDrills(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setIsError(true);
                });
            }
        }
    }, [userId, currentFilters.objectiveType, currentFilters.discipline, isTokenReady]);
    
    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    return (<>
        <Header title={'Insights'} />

        <IconButton style={{position: 'absolute', top: '38px', right: '0', padding: '0', fontSize: "35px", color: 'var(--site-light-text-color)'}} aria-label="delete" size="large" alt='delete' onClick={() => setMenuOpen(!menuOpen)} >
            <MenuIcon fontSize="inherit" />
        </IconButton>

        <div className='insights-main-area'>
            {menuOpen && <>
                <DataFilterSelect currentFilters={currentFilters} filterChange={handleFilterChange} handleCancel={handleCancel} />
            </>}

            {(!menuOpen && currentFilters.objectiveType === 'results') &&
                <ResultsInsight userId={userId} discipline={currentFilters.discipline} />
            }
            {(!menuOpen && currentFilters.objectiveType === 'routines') &&
                <RoutineInsights userId={userId} discipline={currentFilters.discipline} stats={objInsights} userRoutines={userRoutines} />
            }
            {(!menuOpen && currentFilters.objectiveType === 'drills') &&
                <DrillInsights userId={userId} discipline={currentFilters.discipline} stats={objInsights} userDrills={userDrills.filter(item => item.type === currentFilters.discipline)} />
            }
            {(!menuOpen && currentFilters.objectiveType === 'sessions') &&
                <SessionInsights userId={userId} discipline={currentFilters.discipline} />
            }
        </div>
    </>);
};

export default Dashboard;

