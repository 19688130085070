import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FormControl, /*FormControlLabel, FormGroup,*/ Checkbox, Grid, InputLabel, Select, MenuItem, Autocomplete, TextField, Chip } from '@mui/material';
import LoadingMessage from "../../../components/LoadingMessage.js";
import ErrorMessage from "../../../components/ErrorMessage.js";

const ResultsInsight = ({ userId, discipline }) => {
    const [dataExists, setDataExists] = useState(true);
    const [timeframe, setTimeframe] = useState('12');
    const [routineType, setRoutineType] = useState('vol');
    const [selectedMetrics, setSelectedMetrics] = useState(() => {
        switch (discipline) {
            case 'trampoline':
                return {
                    total_score: true,
                    execution: false,
                    hd: false,
                    tof: false,
                    difficulty: false
                };
            case 'synchro':
                return {
                    total_score: true,
                    execution: false,
                    desynch: false,
                    difficulty: false
                };
            default:
                return {
                    total_score: true,
                    execution: false,
                    difficulty: false
                };
        }
    });

    const [jsonData, setJsonData] = useState(null);
    const [yDomain, setYDomain] = useState(null);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_HOST + `/api/results/${discipline}/${userId}`)
        .then(response => {
            const data = response.data;
            if (data !== null) {
                setJsonData(data.map(entry => ({
                    ...entry,
                    result_date: new Date(entry.result_date).toLocaleDateString('en-GB')
                })).sort((a, b) => new Date(b.result_date) - new Date(a.result_date)));
            }
            else {
                setDataExists(false);
            }
        })
        .catch(error => {
            console.error('Error fetching results: ', error);
            setIsError(true);
        });
    }, [userId, discipline]);

    useEffect(() => {
        const getYDomain = () => {
            // Filter data based on selected metrics and 'total_score' condition
            const filteredData = jsonData?.filter(entry => entry.routine_type === routineType)?.filter(entry => {
                if (entry.score_input_type === 'total' && !selectedMetrics.total_score) {
                    return false;
                }
                return true;
            }) || [];

            const selectedData = Object.keys(selectedMetrics)
                .filter(metric => selectedMetrics[metric])
                .flatMap(metric => filteredData.map(entry => entry[metric]));

            const minY = Math.ceil(Math.min(...selectedData) - 1);
            const maxY = Math.floor(Math.max(...selectedData) + 1);

            return [minY, maxY];
        };

        if (jsonData !== null) {
            setYDomain(getYDomain());
        }
    }, [jsonData, selectedMetrics, routineType]);

    // const handleMetricChange = (event) => {
    //     const { name, checked } = event.target;

    //     if (name === 'total_score' && checked) {
    //         setSelectedMetrics(() => {
    //             switch (discipline) {
    //                 case 'trampoline':
    //                     return {
    //                         total_score: true,
    //                         execution: false,
    //                         hd: false,
    //                         tof: false,
    //                         difficulty: false
    //                     };
    //                 case 'synchro':
    //                     return {
    //                         total_score: true,
    //                         execution: false,
    //                         desynch: false,
    //                         difficulty: false
    //                     };
    //                 default:
    //                     return {
    //                         total_score: true,
    //                         execution: false,
    //                         difficulty: false
    //                     };
    //             }
    //         });
    //     } else {
    //         setSelectedMetrics((prevMetrics) => ({
    //             ...prevMetrics,
    //             total_score: false,
    //             [name]: checked
    //         }));
    //     }
    // };

    const handleMetricChange = (newSelectedMetrics) => {
        // Check if 'total_score' is selected
        if (!selectedMetrics.total_score && newSelectedMetrics.includes('total_score')) {
            setSelectedMetrics(() => {
                switch (discipline) {
                    case 'trampoline':
                        return {
                            total_score: true,
                            execution: false,
                            hd: false,
                            tof: false,
                            difficulty: false,
                        };
                    case 'synchro':
                        return {
                            total_score: true,
                            execution: false,
                            desynch: false,
                            difficulty: false,
                        };
                    default:
                        return {
                            total_score: true,
                            execution: false,
                            difficulty: false,
                        };
                }
            });
        } else {
            // Update metrics dynamically based on the new selected options
            setSelectedMetrics((prevMetrics) => {
                const updatedMetrics = { ...prevMetrics };
    
                // Reset all metrics to false
                Object.keys(prevMetrics).forEach((metric) => {
                    updatedMetrics[metric] = false;
                });
    
                // Set the selected metrics to true
                newSelectedMetrics.forEach((metric) => {
                    updatedMetrics[metric] = true;
                });

                updatedMetrics.total_score = false;
    
                return updatedMetrics;
            });
        }
    };
    

    const calculateCutoffDate = (timeframe) => {
        const now = new Date();
        switch (timeframe) {
            case '3':
                return new Date(now.setMonth(now.getMonth() - 3));
            case '6':
                return new Date(now.setMonth(now.getMonth() - 6));
            case '12':
                return new Date(now.setFullYear(now.getFullYear() - 1));
            case 'all':
            default:
                return null; // No cutoff for 'all time'
        }
    };    
    
    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    if (!dataExists) {
        return <p>No data available</p>;
    }

    else if (jsonData === null) {
        return <LoadingMessage title={'Loading...'} />;
    }

    const cutoffDate = calculateCutoffDate(timeframe);
    const filteredData = jsonData.filter(entry => {
        if (discipline === 'trampoline' && entry.routine_type !== routineType) {
            return false;
        }
        return true;
    })?.filter(entry => {
        const entryDate = new Date(entry.result_date.split('/').reverse().join('-')); // Convert 'dd/mm/yyyy' to Date
        if (cutoffDate && entryDate < cutoffDate) {
            return false;
        }
        if (entry.score_input_type === 'total' && !selectedMetrics.total_score) {
            return false;
        }
        return true;
    });

    const metrics = Object.keys(selectedMetrics);

    // const handleChange = (event, newValue) => {
    //     const updatedMetrics = { ...selectedMetrics };
    //     metrics.forEach((metric) => {
    //     updatedMetrics[metric] = newValue.includes(metric);
    //     });
    //     handleMetricChange(updatedMetrics);
    // };



    return (<>
    <form className='new-entry'>
        {discipline === 'trampoline' && 
            <FormControl style={{ width: '49.5%', margin: "5px 1% 10px 0" }}>
                <InputLabel id="objective-label">Routine Type</InputLabel>
                <Select
                    labelId="objective-label"
                    label="Routine Type"
                    variant="outlined"
                    value={routineType}
                    onChange={() => setRoutineType(routineType === 'vol' ? 'set' : 'vol')}
                >
                    <MenuItem value={'vol'}>Voluntary (Vol)</MenuItem>
                    <MenuItem value={'set'}>Compulsory (Set)</MenuItem>
                </Select>
            </FormControl>
        }

        <FormControl style={{ width: discipline === 'trampoline' ? '49.5%' : '100%', margin: "5px 0 10px", background: "#fff", borderRadius: "10px" }}>
            <InputLabel id="timeframe-label">Timeframe</InputLabel>
            <Select
                labelId="timeframe-label"
                label="timeframe"
                variant="outlined"
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
            >
                <MenuItem value={'3'}>3 Months</MenuItem>
                <MenuItem value={'6'}>6 Months</MenuItem>
                <MenuItem value={'12'}>12 Months</MenuItem>
                <MenuItem value={'all'}>All time</MenuItem>
            </Select>
        </FormControl>

        <Grid container spacing={2}>
            <Grid item xs={12}>
            <Autocomplete
                multiple
                options={metrics}
                value={metrics.filter((metric) => selectedMetrics[metric])}
                onChange={(event, newValue) => handleMetricChange(newValue)} // Pass selected metrics as an array
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                    />
                    {option.replace('_', ' ').toUpperCase()}
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip
                        key={option}
                        label={option.replace('_', ' ').toUpperCase()}
                        {...getTagProps({ index })}
                    />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Select Metrics"
                        placeholder="Metrics"
                    />
                )}
                />

                {/* <FormControl component="fieldset">
                    <FormGroup row>
                        {Object.keys(selectedMetrics).map((metric) => (
                            <FormControlLabel
                                key={metric}
                                control={
                                    <Checkbox
                                        checked={selectedMetrics[metric]}
                                        onChange={handleMetricChange}
                                        name={metric}
                                        color="primary"
                                    />
                                }
                                label={metric.replace('_', ' ').toUpperCase()}
                            />
                        ))}
                    </FormGroup>
                </FormControl> */}
            </Grid>
            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={filteredData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                            dataKey="result_date" 
                            tick={{ fontSize: 14 }} 
                            // label={{ value: "Date", position: "insideBottom", fontSize: 16, dy: 8 }} 
                            reversed={true} 
                        />
                        <YAxis 
                            domain={yDomain} 
                            tick={{ fontSize: 14 }} 
                            label={{ value: "Score", angle: -90, position: "insideLeft", fontSize: 16 }} 
                        />
                        <Tooltip 
                            formatter={(value, name) => [`${name.replace('_', ' ')}: ${value}`]}
                            labelFormatter={(label) => `Date: ${label}`}
                            contentStyle={{ fontSize: 14 }}
                            labelStyle={{ fontSize: 16 }}
                            itemStyle={{ fontSize: 16 }}
                        />
                        <Legend 
                            formatter={(value) => {
                                if (value === 'total_score') return 'Total Score';
                                return value.replace('_', ' ').toUpperCase();
                            }} 
                            wrapperStyle={{ fontSize: '16px' }} 
                        />
                        {Object.keys(selectedMetrics).map((metric, index) =>
                            selectedMetrics[metric] && (
                                <Line
                                    key={metric}
                                    type="monotone"
                                    dataKey={metric}
                                    stroke={`hsl(${index * 60}, 70%, 50%)`} 
                                    activeDot={{ r: 8 }}
                                />
                            )
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    </form>
    </>);
}

export default ResultsInsight;
