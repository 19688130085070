import { useState, useEffect } from "react";
import { fetchAuthSession } from '@aws-amplify/auth';
import axios from "axios";
import { Tab, Tabs, Box, Fab, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add'
import AddRoutine from "./components/AddRoutine";
import RoutineEntry from "./components/RoutineEntry";
import AddPass from "./components/AddPass";
import PassEntry from "./components/PassEntry";
import AddTumbleRun from "./components/AddTumbleRun.js";
import TumbleEntry from "./components/TumbleEntry.js";
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox";
import { useData } from "../../DataContext";
import LoadingMessage from "../../components/LoadingMessage";
import Header from "../../components/general/Header.tsx";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";
import ErrorMessage from "../../components/ErrorMessage.js";

const Routines = ({ user }) => {

    const userId = user.userId;
    const [userGender, setUserGender] = useState(null);
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);

    const blankRoutine = {
        user_id: userId,
        name: '',
        status: 'active',
        routine: {
            routine_id: '1-1-1-1-1-1-1-1-1-1',
            skills: [1,1,1,1,1,1,1,1,1,1], 
            difficulty: 0.0,
            new_difficulty: 0.0
        }
    };

    const blankPass = {
        user_id: userId,
        name: '',
        status: 'active',
        pass: {
            pass_id: '3-3',
            skills: [3,3], 
            difficulty: 0.0
        }
    };

    const blankTumble = {
        user_id: userId,
        name: '',
        status: 'active',
        routine: {
            pass_id: '1-4-4-4-4-4-4-5',
            skills: [1,4,4,4,4,4,4,5], 
            difficulty: 1.8
        }
    };

    const [state, setState] = useState('loading');
    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [routinePlaceholder, setRoutinePlaceholder] = useState(blankRoutine);
    const [routines, setRoutines] = useState([]);
    const [passPlaceholder, setPassPlaceholder] = useState(blankPass);
    const [passes, setPasses] = useState([]);
    const [tumblePlaceholder, setTumblePlaceholder] = useState(blankTumble);
    const [tumbles, setTumbles] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [trampRoutineNames, setTrampRoutineNames] = useState([]);
    const [dmtPassNames, setDmtPassNames] = useState([]);
    const [tumblingRunNames, setTumblingRunNames] = useState([]);

    const { skillsList, dmtSkillsList, tumbleSkillsList } = useData();

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
      }, []);

    useEffect(() => {
        if (isTokenReady) {

            axios.get(process.env.REACT_APP_API_HOST + `/api/routines/trampoline/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setRoutines(data);
                }
            })
            .catch(error => {
                console.error('Error fetching user routines: ', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/routines/dmt/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setPasses(data);
                }
            })
            .catch(error => {
                console.error('Error fetching user tumbles: ', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/routines/tumbling/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setTumbles(data);
                }
            })
            .catch(error => {
                console.error('Error fetching user passes: ', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/user/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setUserGender(data.gender);
                }
            })
            .catch(error => {
                console.error('Error fetching user details: ', error);
                setIsError(true);
            });
            setState('view');
        }
        
    }, [userId, isTokenReady]); 

    useEffect(() => {
        setTrampRoutineNames(routines.map(r => r.name.toLowerCase()));
    }, [routines]);

    useEffect(() => {
        setDmtPassNames(passes.map(p => p.name.toLowerCase()));
    }, [passes]);

    useEffect(() => {
        setTumblingRunNames(tumbles.map(t => t.name.toLowerCase()));
    }, [tumbles]);

    const handleNewRoutine = (discipline, newRoutine) => {

        if (state === 'entering-tri' || state === 'entering-dmt' || state === 'entering-tum' || state === 'view') {
            axios.post(process.env.REACT_APP_API_HOST + `/api/routines/${discipline}`, newRoutine, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                if (discipline === 'trampoline') {setRoutines([{...newRoutine, id: res.data.last_id}, ...routines])}
                else if (discipline === 'dmt') {setPasses([{...newRoutine, id: res.data.last_id}, ...passes])}  
                else if (discipline === 'tumbling') {setTumbles([{...newRoutine, id: res.data.last_id}, ...tumbles])}         
            })
            .catch(error => {
                console.error('Error posting trampoline entry details:', error);
                setIsError(true);
            }); 
        } else {
            axios.patch(process.env.REACT_APP_API_HOST + `/api/routines/${discipline}`, newRoutine, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                if (discipline === 'trampoline') {
                    setRoutines(routines.map(routine => {
                        if (routine.id === newRoutine.id) {
                            return newRoutine;
                        }
                        return routine;
                    }));
                }
                else if (discipline === 'dmt') {
                    setPasses(passes.map(pass => {
                        if (pass.id === newRoutine.id) {
                            return newRoutine;
                        }
                        return pass;
                    }));
                }  
                else if (discipline === 'tumbling') {
                    setTumbles(tumbles.map(tumble => {
                        if (tumble.id === newRoutine.id) {
                            return newRoutine;
                        }
                        return tumble;
                    }));
                }   
            })
            .catch(error => {
                console.error('Error posting routine details:', error);
                setIsError(true);
            }); 
        }
        setState('view');
    };

    const handleEditing = (discipline, routine) => {
        switch (discipline) {
            case 'trampoline':
                setRoutinePlaceholder(routine);
                setState('editing-tri');
                break;
            case 'dmt':
                setPassPlaceholder(routine);
                setState('editing-dmt');
                break;
            case 'tumbling':
                setTumblePlaceholder(routine);
                setState('editing-tum');
                break;
            default:
                console.log('Discipline not recognised');
        };
        
    }

    const handleDuplicate = (discipline, routine) => {
        const newRoutine = {...routine, name: routine.name + ' (1)', status: 'active'};
        switch (discipline) {
            case 'trampoline':
                setRoutinePlaceholder(newRoutine);
                setState('entering-tri');
                break;
            case 'dmt':
                setPassPlaceholder(newRoutine);
                setState('entering-dmt');
                break;
            case 'tumbling':
                setTumblePlaceholder(newRoutine);
                setState('entering-tum');
                break;
            default:
                console.log('Discipline not recognised');
        };
    }

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const removeEntry = (id) => {
        let discipline = 'trampoline';
        switch (tabValue) {
            case 1:
                discipline = 'dmt';
                break;
            case 2:
                discipline = 'tumbling';
                break;
            default:
                discipline = 'trampoline'
                break;
        };

        axios.delete(process.env.REACT_APP_API_HOST + `/api/routines/${discipline}/${userId}/${id}`)
        .then(() => {
            if (discipline === 'trampoline') {setRoutines(routines.filter(item => item.id !== id))}
            else if (discipline === 'dmt') {setPasses(passes.filter(item => item.id !== id))}
            else if (discipline === 'tumbling') {setTumbles(tumbles.filter(item => item.id !== id))}
            
        })
        .catch(error => {
            console.error('Error deleting routine', error);
            setIsError(true);
        });
        setConfirmModal(false);
        setIdToDelete(0);
    };
    
    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    };

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;

    if (!skillsList?.length || !dmtSkillsList?.length || !tumbleSkillsList?.length || state === 'loading') {
        return <LoadingMessage title={'Routines'} />
    }

    return (
        <>
            {state !== 'view' &&
                <Box sx={{ fontSize: '40px', textAlign: 'center', height: '60px', position: 'fixed', top: '20px', left: '50%', transform: 'translateX(-50%)', width: 'calc(100% - 40px)' }}>
                    <IconButton style={{position: 'absolute', top: '13px', left: '0', padding: '0', fontSize: "40px", color: "var(--site-light-text-color)"}} 
                        aria-label="back" size="large" alt='back' 
                        onClick={cancelEntry}
                    >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            }
            <Header title={state === 'view' ? 'Routines' : '  Add Routine'} />

            <Box sx={{ width: '100%', borderRadius: '10px', borderBottom: 1, borderColor: 'divider', overflow:'hidden' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Trampoline" sx={{width: '33%', fontSize: '1.4rem'}} {...a11yProps(0)}  />
                    <Tab label="DMT" sx={{width: '33%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                    <Tab label="Tumbling" sx={{width: '33%', fontSize: '1.4rem'}} {...a11yProps(2)} />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                {(state === 'view' && routines?.length > 0) &&
                    <div className='entries-in-tabs'>
                        {routines?.map((routine, index) => (
                            <RoutineEntry key={index}  skillsList={skillsList} routine={routine} index={index} setEditing={handleEditing} duplicate={handleDuplicate} remove={handleConfirmRemove} />
                        ))}
                    </div>
                } 
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {(state === 'view' && passes?.length > 0) &&
                <div className='entries-in-tabs'>
                    {passes?.map((pass, index) => (
                        <PassEntry key={index}  skillsList={dmtSkillsList} pass={pass} index={index} setEditing={handleEditing} duplicate={handleDuplicate} remove={handleConfirmRemove} />
                    ))}
                </div>
                } 
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                {(state === 'view' && tumbles?.length > 0) &&
                <div className='entries-in-tabs'>
                    {tumbles?.map((routine, index) => (
                        <TumbleEntry key={index}  skillsList={tumbleSkillsList} routine={routine} index={index} setEditing={handleEditing} duplicate={handleDuplicate} remove={handleConfirmRemove} />
                    ))}
                </div>
                } 
            </TabPanel>

            {(state === 'entering-tri' || state === 'editing-tri') &&
                <AddRoutine type={state} routineHolder={routinePlaceholder} skillsList={skillsList} names={trampRoutineNames} handleNewEntry={handleNewRoutine} handleCancel={cancelEntry} gender={userGender} />
            }

            {(state === 'entering-dmt' || state === 'editing-dmt') &&
                <AddPass type={state} passHolder={passPlaceholder} skillsList={dmtSkillsList} names={dmtPassNames} handleNewEntry={handleNewRoutine} handleCancel={cancelEntry} />
            }

            {(state === 'entering-tum' || state === 'editing-tum') &&
                <AddTumbleRun type={state} routineHolder={tumblePlaceholder} skillsList={tumbleSkillsList} names={tumblingRunNames} handleNewEntry={handleNewRoutine} handleCancel={cancelEntry} />
            }

            { confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this routine?"} handleConfirm={() => removeEntry(idToDelete)} handleCancel={cancelEntry}/>
            }

            {state === 'view' &&
                <Fab color="secondary" aria-label="add" style={{ position: 'absolute', bottom: '20px', right: '0' }}
                    onClick={() => {if (tabValue === 0) {setRoutinePlaceholder(blankRoutine); setState('entering-tri')}
                            else if (tabValue === 1) {setPassPlaceholder(blankPass); setState('entering-dmt')}
                            else if (tabValue === 2) {setTumblePlaceholder(blankTumble); setState('entering-tum')}
                            }} 
                    >
                    <AddIcon sx={{ fontSize: '3rem' }} />
                </Fab>
            }
        </>
    )
}

export default Routines;