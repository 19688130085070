import { useEffect, useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Box, CircularProgress } from "@mui/material";
import SkillBrick from "./SkillBrick";
import PlaySkillFocus from './PlaySkillFocus';
import ErrorMessage from "../../../components/ErrorMessage.js";


const PlaySkills = ({user}) => {

    const userId = user.userId;
    const [skillsList, setSkillsList] = useState([]);
    const [focusSkill, setFocusSkill] = useState(null);
    const [state, setState] = useState('view');
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
    }, []);
    
    useEffect(() => {
        if (isTokenReady) {
            axios.get(process.env.REACT_APP_API_HOST + `/api/play-skills/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setSkillsList(data);
                }
            })
            .catch(error => {
                console.error('Error fetching user play skills:', error);
                setIsError(true);
            });
        }
    }, [userId, isTokenReady]); 


    const handleFocus = (playSkill) => {
        setFocusSkill(playSkill);
        setState('focus');
    };

    const handleUpdate = (attempts, proficiency) => {
        const newSkill = {
            ...focusSkill,
            attempts: attempts,
            proficiency: proficiency
        }
        axios.post(process.env.REACT_APP_API_HOST + '/api/play-skills', newSkill, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(() => {
            setSkillsList(skillsList.map(skill => {
                if (skill.skill_id === focusSkill.skill_id) {
                    return newSkill;
                }
                else {
                    return skill;
                }
            }));
            setState('view');
        })
        .catch(error => {
            console.error('Error posting user play skill:', error);
            setIsError(true);
        });  
    };

    const handleExit = () => {
        setState('view');
    };

    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;
    
    if (skillsList?.length === 0) {
        return (
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '65dvh',  // Full viewport height to center the content
                }}
            >
            <CircularProgress />
                <h3 style={{marginTop: '10px' }}>Loading...</h3>
            </Box>
        );
    }

    return (
        <>
            <div className='entries-in-tabs'>
                {skillsList?.map(skill => (
                    <SkillBrick className='entry' key={skill.skill_id} playSkill={skill} handleFocus={handleFocus} />
                ))}
            </div>

            {state === 'focus' && 
                <PlaySkillFocus playSkill={focusSkill} handleUpdate={handleUpdate} handleExit={handleExit} />
            }
        </>
    )

};

export default PlaySkills;
