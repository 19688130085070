import { useState } from "react";
import { Button } from "@mui/material";

const HydrationTracker = ({current, handleSave, handleCancel}) => {

    const [water, setWater] = useState(250);

    return (
        <div className="form-wrapper">
            <div className="confirm-modal" style={{backgroundColor: 'var(--site-light-background-color)', borderRadius: '15px'}}>
                <h2>Hydration Tracker</h2>
                <p style={{margin: '10% 0 5px'}}>How much have you drank?</p>
                <div className="flex-buttons" style={{margin: '0 20% 10%'}}>
                    <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => setWater(water - 50)}>-</Button>
                    <p style={{fontWeight: 'bold'}}>{water}ml</p>
                    <Button className="add-subtract-button" variant="outlined" size="medium" onClick={() => setWater(water + 50)}>+</Button>
                </div>
                <p>Consumed so far: <b style={{fontWeight: 'bold'}}>{current}ml</b></p>
            </div>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={() => handleSave(water)}
                    variant="contained"
                >
                    Save
                </Button>

                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
};

export default HydrationTracker;