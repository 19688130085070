import { useState } from "react";
import { ToggleButton, ToggleButtonGroup, TextField, Button, Autocomplete } from "@mui/material";

const AddTumbleRun = ({type, routineHolder, skillsList, names, gender, handleNewEntry, handleCancel}) => {

    const [routine, setRoutine] = useState(routineHolder);
    const [routineSkills, setRoutineSkills] = useState(routineHolder.routine.skills.map(skillId => skillsList.filter(skill => skill.id === skillId)[0]));

    const handleFieldChange = (e, fieldName) => {
        setRoutine({
            ...routine,
            [fieldName]: e.target.value
        })
    };

    const calculateDifficulty = (skills, skillsList) => {
        const baseDifficulty = skills.reduce((total, skillId) => {
            const skill = skillsList.find(skill => skill.id === skillId);
            return total + (skill ? skill.difficulty : 0);
        }, 0);

        const threshold = gender === 'female' ? 2.0 : 4.4;

        const bonusEligibleCount = skills
            .map(skillId => skillsList.find(skill => skill.id === skillId))
            .filter(skill => skill && skill.difficulty >= threshold).length;

        const bonus = Math.max(0, (bonusEligibleCount - 1) * 1.0);

        return parseFloat((baseDifficulty + bonus).toFixed(1));
    };

    const handleSkillChange = (e, index) => {
        const selectedSkillName = e.target.innerText;

        if (selectedSkillName) {
            const selectedSkill = skillsList.find(skill => 
                (skill.name + ' (' + skill.fig + skill.shape + ')') === selectedSkillName
            );
            
            if (selectedSkill) {
                const newRoutineSkills = [...routineSkills];
                newRoutineSkills[index] = selectedSkill;
                setRoutineSkills(newRoutineSkills);

                const updatedSkills = [...routine.routine.skills];
                updatedSkills[index] = selectedSkill.id;

                const newDifficulty = calculateDifficulty(updatedSkills, skillsList, gender);

                setRoutine(prevRoutine => ({
                    ...prevRoutine,
                    routine: {
                        ...prevRoutine.routine,
                        skills: updatedSkills,
                        difficulty: newDifficulty
                    }
                }));
            }
        }
    };


    const handleSubmit = () => {
        let newRoutine = routine.routine;

        const routine_id = routine.routine.skills.join('-');
        newRoutine = {
            ...newRoutine,
            routine_id: routine_id
        };

        handleNewEntry('tumbling', {
            ...routine,
            routine: newRoutine});
    };

    return (
        <form className="new-entry" style={{padding: '5px'}}>
              
            <TextField fullWidth style={{ marginTop: "5px" }}
                type="text"
                id="routine-name"
                label="Routine Name"
                variant="outlined"
                value={routine.name}
                onChange={(e) => handleFieldChange(e, 'name')}
            />

            {type === 'editing-tum' && <>
                <ToggleButtonGroup
                    fullWidth
                    style={{margin: '10px 0 5px'}}
                    color="primary"
                    value={routine.status}
                    exclusive
                    onChange={(e) => handleFieldChange(e, 'status')}
                    aria-label="active status"
                >
                    <ToggleButton color="success" value={'active'} aria-label="active">Active</ToggleButton>
                    <ToggleButton color="error" value={'inactive'} aria-label="inactive">Inactive</ToggleButton>
                </ToggleButtonGroup>
            </>}

            {routine.routine.skills.map((skill, index) => 
                <Autocomplete 
                    key={index} 
                    style={{marginTop: '10px'}} 
                    options={skillsList} 
                    getOptionLabel={(option) => (option.name + ' (' + option.fig + option.shape + ')')} 
                    isOptionEqualToValue={() => true}
                    value={skillsList.filter((skill) => skill.id === routine.routine.skills[index])[0]}
                    onChange={(e) => {handleSkillChange(e, index)}}
                    renderInput={(params) => (
                        <TextField {...params} label={"Search or select a skill"} variant="outlined" />
                    )}
                />
            )}

            <p>Difficulty: {routine.routine.difficulty }</p>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "5px 5px 5px"}}
                    disabled={routine.name === '' || (type === 'entering-tum' && names.includes(routine.name.toLowerCase()))}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    {type === 'entering-tum' ? 'Add routine' : 'Update routine'}
                </Button>

                <Button
                    style={{margin: "5px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </form>
    )

}

export default AddTumbleRun;