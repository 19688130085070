import { useState } from "react";
import { Autocomplete, TextField, Button } from "@mui/material";

const AddNewSkill = ({skills, handleAdd, handleExit}) => {

    const [newSkill, setNewSkill] = useState({
        in_rig: 0,
        to_pit: 0,
        mat_with_catch: 0,
        to_mat: 0,
        to_bed: 0,
        in_combination: 0,
        in_routine: 0,
        competed: 0 
    });

    return (
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}>
            
            <div className="form-wrapper" style={{ width: 'calc(100% - 40px)', position: 'absolute', top: '0', bottom: '0', margin:'auto 20px', height: 'fit-content'}}> 
               
                <div style={{ borderRadius: '10px', backgroundColor: 'var(--site-light-background-color)', width: '100%', paddingBottom: '5px'}}>
                    <h2>New Skill</h2>  
                    <Autocomplete
                        style={{ margin: '5%', backgroundColor: '#fff', borderRadius: '5px' }}
                        options={skills}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={() => true} // Hardcoded to true temporarily
                        onChange={(e, newValue) => setNewSkill({
                            ...newSkill,
                            name: newValue.name,
                            skill_id: newValue.id
                        })}
                        renderInput={(params) => (
                            <TextField {...params} label={"Search or select a skill"} variant="outlined" />
                        )}
                    />
                    
                    <div className='bottom-buttons'>
                        <Button
                            style={{margin: "0 5px 5px", width: '100px'}}
                            onClick={() => handleAdd(newSkill)}
                            variant="contained"
                        >
                            Add
                        </Button>

                        <Button
                            style={{margin: "0 5px 5px", width: '100px'}}
                            onClick={handleExit}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>    

                
            </div>      
        </div>
    );
};

export default AddNewSkill;