import { useState } from "react";
import { ToggleButton, ToggleButtonGroup, TextField, Button, Autocomplete } from "@mui/material";

const AddPass = ({type, passHolder, skillsList, names, handleNewEntry, handleCancel}) => {
    const [pass, setPass] = useState(passHolder);

    const handleFieldChange = (e, fieldName) => {
        setPass({
            ...pass,
            [fieldName]: e.target.value
        } )
    };

    const handleSkillChange = (e, index) => {
        let newPass = pass.pass;
    
        const selectedSkill = e.target.innerText;

        if (selectedSkill !== undefined && selectedSkill.length !== 0) {

            newPass.skills[index] = skillsList.find(value => 
                (value.name + ' (' + value.fig + value.shape + ')') === selectedSkill
            ).id;

            newPass = {
                ...newPass,
                difficulty: parseFloat(newPass.skills.reduce((total, current) => 
                    total + skillsList.filter(value => value.id === current)[0].difficulty, 0
                ).toFixed(1))
            };
    
            setPass({
                ...pass,
                pass: newPass
            });
        }
    };
    

    const handleSubmit = () => {
        let newPass = pass.pass;

        const pass_id = pass.pass.skills.join('-');
        newPass = {
            ...newPass,
            pass_id: pass_id
        };

        handleNewEntry('dmt', {
            ...pass,
            pass: newPass});
    };

    return (
        <form className="new-entry" style={{padding: '5px'}}>
               
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                id="pass-name"
                label="Pass Name"
                variant="outlined"
                value={pass.name}
                onChange={(e) => handleFieldChange(e, 'name')}
            />

            {type === 'editing-dmt' && <>
                <ToggleButtonGroup
                    fullWidth
                    style={{margin: '10px 0 5px'}}
                    color="primary"
                    value={pass.status}
                    exclusive
                    onChange={(e) => handleFieldChange(e, 'status')}
                    aria-label="active status"
                >
                    <ToggleButton color="success" value={'active'} aria-label="active">Active</ToggleButton>
                    <ToggleButton color="error" value={'inactive'} aria-label="inactive">Inactive</ToggleButton>
                </ToggleButtonGroup>
            </>}

            
            {pass.pass.skills.map((skill, index) => 
                <Autocomplete 
                    key={index} 
                    // disabled={type === 'editing'}
                    style={{marginTop: '10px'}} 
                    options={skillsList} 
                    getOptionLabel={(option) => (option.name + ' (' + option.fig + option.shape + ')')} 
                    isOptionEqualToValue={/*(option, value) => option.id === value.id*/ () => true} //hardcoded to true temporarily
                    value={skillsList.filter((skill) => skill.id === pass.pass.skills[index])[0]}
                    onChange={(e) => {handleSkillChange(e, index)}}
                    renderInput={(params) => (
                        <TextField {...params} label={"Search or select a skill"} variant="outlined" />
                    )}
                />
            )}

            <p>{'Difficulty: ' + pass.pass.difficulty}</p>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px"}}
                    disabled={pass.name === '' || (type === 'entering-dmt' && names.includes(pass.name.toLowerCase()))}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    {type === 'entering-dmt' ? 'Add pass' : 'Update pass'}
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </form>
    )

}

export default AddPass;