import { Button } from "@mui/material";

const ErrorMessage = ({handleConfirm}) => {


    return (<>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}></div>
        <div style={{backgroundColor: '#fff', border: "2px solid #545454", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000', padding: '10px'}}>
            <h2>Error</h2>
            <p style={{marginTop: '10px', fontSize: '1.6rem', fontWeight: '600'}}>We're sorry, an error has occurred.</p>
            <p style={{fontSize: '1.6rem', fontWeight: '600'}}>Please log back in and try again.</p>
            <p style={{marginBottom: '10px', fontSize: '1.6rem', fontWeight: '600'}}>If the issue persists, please contact support@digitaltrainingdiary.com</p>

            <div className="flex-buttons">
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleConfirm}
                    variant="contained"
                >
                    Close
                </Button>
            </div>
            
        </div>
    </>)
}

export default ErrorMessage;