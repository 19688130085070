import { Fab } from "@mui/material";
import { useState, useEffect } from "react";
import { fetchAuthSession } from '@aws-amplify/auth';
import axios from "axios";
import AddIcon from '@mui/icons-material/Add';
import SessionEntry from "./components/SessionEntry.js";
import CreateTrainingSession from "./components/CreateTrainingSession.js";
import Header from "../../components/general/Header.tsx";
import LoadingMessage from "../../components/LoadingMessage";
import ErrorMessage from "../../components/ErrorMessage.js";


const DiarySelection = ({ user }) => {

    const userId = user.userId;

    const [state, setState] = useState('loading');
    const [sessions, setSessions] = useState([]);
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
    }, []);
   
    useEffect(() => {
        if (isTokenReady) {
            axios.get(process.env.REACT_APP_API_HOST + `/api/sessions/${userId}`)
            .then(response => {
                const sessions = response.data;
                setSessions(sessions);
                setState('select');
            })
            .catch(error => {
                console.error('Error fetching sessions:', error);
                setIsError(true);
            });
        }
    }, [userId, isTokenReady]);

    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;

    if (state === 'loading') {
        return <LoadingMessage title={'Sessions'} />
    }

    return (
        <>
            {state === 'select' && 
                <Header title={'Sessions'} />
            }
            
            {state === 'create' && 
                <CreateTrainingSession userId={userId} defaultSlide={3} />
            }
             
            {(state === 'select' && sessions?.length !== 0) &&
                <div className='entries'>
                    {sessions?.map((session) => (
                        <SessionEntry key={session.id} session={session} />
                    ))} 
                </div>
            }   
            {(state === 'select') &&
                <Fab color="secondary" aria-label="add" onClick={() => { setState('create')}} style={{ position: 'absolute', bottom: '20px', right: '0px' }}>
                    <AddIcon sx={{ fontSize: '3rem' }} />
                </Fab>
            }    
        </>
    )
};

export default DiarySelection;