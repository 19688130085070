const filterSkillsList = (skillsList, skills, index, numSkills ) => {
    
    // First skill from feet
    if (index === 0) {
        return skillsList.filter(skill => skill.from === 'Feet');
    } 

    // Only show skills starting from body part previous skill landed on
    let prevSkillTo = skillsList.filter(skill => skill.id === skills[index - 1])[0].to;
    let filteredList = skillsList.filter(skill => skill.from === prevSkillTo)

    // Last skill to feet
    if (index === numSkills - 1) {
        filteredList = filteredList.filter(skill => skill.to === 'Feet');
    }
    
    return filteredList;
};

export default filterSkillsList;