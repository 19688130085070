import { useEffect, useState } from "react";
import axios from "axios";
import TabPanel, {a11yProps} from "../../components/general/TabPanel";
import { Box, Tabs, Tab, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomEntry from './components/manager/CustomEntry';
import CreateConditioningCircuit from './components/manager/CreateConditioningCircuit.js';
import CreateCustomExercise from './components/manager/CreateCustomExercise.js';
import CreateConditioningTest from './components/manager/CreateConditioningTest';
import RenameConditioningItem from "./components/manager/RenameConditioningItem.js";
import ErrorMessage from "../../components/ErrorMessage.js";
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox.js";

const ExerciseManager = ({user}) => {

    const userId = user.userId;
    const [tabValue, setTabValue] = useState(0);
    const [exercises, setExercises] = useState([]);
    const [userCircuits, setUserCircuits] = useState([]);
    const [userTests, setUserTests] = useState([]);
    const [state, setState] = useState('loading');
    const [isError, setIsError] = useState(false);
    const [customExNames, setCustomExNames] = useState([]);
    const [circuitNames, setCircuitNames] = useState([]);
    const [testNames, setTestNames] = useState([]);
    const [isRenaming, setIsRenaming] = useState(false);
    const [names, setNames] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [currentType, setCurrentType] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({type: '', item: {}});
    
    const tabMap = ['exercises','createExercise', 'createCircuit', 'createTest'];

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/exercises/${userId}`)
        .then(response => {
            const data = response.data;
            if (data !== null) {
                setExercises(data);
            }
        })
        .catch(error => {
            console.error('Error fetching user conditioning exercises:', error);
            setIsError(true);
        });

        axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/user-circuits/${userId}`)
        .then(response => {
            const data = response.data;
            if (data !== null) { 
                setUserCircuits(data);
            }
        })
        .catch(error => {
            console.error('Error fetching user circuits:', error);
            setIsError(true);
        });

        axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/tests/${userId}`)
        .then(response => {
            const data = response.data;
            if (data !== null) {
                setUserTests(data);
            }
        })
        .catch(error => {
            console.error('Error fetching user conditioning tests:', error);
            setIsError(true);
        });

    }, [userId])

    useEffect(() => {
        const exNames = exercises
            .filter(e => e.type === 'custom')
            .map(e => e.name.toLowerCase());
    
        setCustomExNames(exNames);
    }, [exercises]);

    useEffect(() => {
        setCircuitNames(userCircuits.map(c => c.title.toLowerCase()));
    }, [userCircuits]);

    useEffect(() => {
        setTestNames(userTests.map(t => t.title.toLowerCase()));
    }, [userTests]);

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    const handleSwitchChange = (type, item, newStatus) => {

        if (type === 'exercises') {
            axios.patch(process.env.REACT_APP_API_HOST + `/api/conditioning/exercises`, 
                {
                    user_id: userId,
                    id: item.id,
                    name: item.name,
                    status: newStatus
                }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setExercises(exercises.map(ex => {
                    if (ex.id === item.id) {
                        return {...ex, name: item.name, status: newStatus}
                    }
                    return ex
                }));
            })
            .catch(error => {
                console.error(`Error updating exercises details:`, error);
                setIsError(true);
            }); 
        } else {
            const idField = type === 'tests' ? 'test_id' : 'id';
            axios.put(process.env.REACT_APP_API_HOST + `/api/conditioning/${type}`, 
                {
                    user_id: userId,
                    [idField]: type === 'tests' ? item.test_id : item.id,
                    title: item.title,
                    status: newStatus
                }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                if (type === 'tests') {
                    setUserTests(userTests.map(test => {
                        if (test.test_id === item.test_id) {
                            return {...test, title: item.title, status: newStatus}
                        }
                        return test
                    }));
                } else {
                    setUserCircuits(userCircuits.map(circuit => {
                        if (circuit.id === item.id) {
                            return {...circuit, title: item.title, status: newStatus}
                        }
                        return circuit
                    }));
                }
            })
            .catch(error => {
                console.error(`Error updating exercises details:`, error);
                setIsError(true);
            }); 
        }
    }

    const handleNewUserCircuit = (newCircuit) => {
        const circuit = {
            user_id: userId,
            title: newCircuit.title,
            exercises: newCircuit.exercises
        };
        axios.post(process.env.REACT_APP_API_HOST + `/api/conditioning/user-circuits`, circuit)
        .then(response => {
            const { last_id } = response.data;
            const newCircuitWithId = {...circuit, id: last_id, status: 'active'};
            setUserCircuits([newCircuitWithId, ...userCircuits]);
            setState('view');
        })
        .catch(error => {
            console.error(`Error updating exercises details:`, error);
            setIsError(true);
        }); 
    };

    const handleNewCustomExercise = (exName) => {
        const newExercise = {
            user_id: userId,
            name: exName
        };
        axios.post(process.env.REACT_APP_API_HOST + `/api/conditioning/exercises`, newExercise)
        .then(response => {
            const { last_id } = response.data;
            setExercises([{name: exName, id: last_id, type: 'custom', status: 'active'}, ...exercises]);
            setState('view');
        })
        .catch(error => {
            console.error(`Error updating exercises details:`, error);
            setIsError(true);
        }); 
    }

    const handleNewTest = (newTest) => {
        const test = {
            user_id: userId,
            title: newTest.title,
            test: newTest
        };

        axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/tests', test, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            const { last_id } = response.data;
            const newUserTest = {test_id: last_id, user_id: userId, title: newTest.title, status: 'active'};
            setUserTests([newUserTest, ...userTests]);
            setState('view');
        })
        .catch(error => {
            console.error('Error posting conditioning test:', error);
            setIsError(true);
        });  
        
    };

    const handleConfirmRemove = (type, item) => {
        setItemToDelete({type: type, item: item});
        setConfirmModal(true);
    }

    const handleDelete = (type, item) => {
        const idField = type === 'tests' ? 'test_id' : 'id';
        axios.delete(process.env.REACT_APP_API_HOST + `/api/conditioning/${type}/${userId}/${item[idField]}`)
        .then(() => {
            if (type === 'exercises') {
                setExercises(exercises.filter(e => !(e.id === item.id && e.type === 'custom')));
            }
            if (type === 'tests') {
                setUserTests(userTests.filter(t => t.test_id !== item.test_id));
            } else {
                setUserCircuits(userCircuits.filter(c => c.id !== item.id));
            }
            setConfirmModal(false);
        })
        .catch(error => {
            if (error.code === 'ERR_BAD_REQUEST') {
                setConfirmModal(false);
            } else {
                console.error('Error deleting conditioning item', error);
                setConfirmModal(false);
                setIsError(true);
            }
        });
    };

    const setRenaming = (type, item) => {
        setCurrentType(type);
        setCurrentItem(item);
        setNames(() => {
            if (type === 'exercises') {
                return customExNames;
            } else if (type === 'user-circuits') {
                return circuitNames;
            } else {
                return testNames;
            }
        });
        setIsRenaming(true);
    };

    const handleRename = (type, item) => {
        if (type === 'exercises') {
            const newItem = {...item, user_id: userId};
            axios.patch(process.env.REACT_APP_API_HOST + `/api/conditioning/${type}`, newItem, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setExercises(exercises.map(e => {
                    if (e.id === item.id && e.type === 'custom') {
                        return item;
                    }
                    return e;
                }));
                setIsRenaming(false);
            })
            .catch(error => {
                console.error('Error renaming conditioning item', error);
                setIsRenaming(false);
                setIsError(true);
            });
        } else {
            axios.put(process.env.REACT_APP_API_HOST + `/api/conditioning/${type}`, item, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                if (type === 'tests') {
                    setUserTests(userTests.map(t => {
                        if (t.test_id === item.test_id) {
                            return item;
                        }
                        return t;
                    }));
                } else {
                    setUserCircuits(userCircuits.map(c => {
                        if (c.id === item.id) {
                            return item;
                        }
                        return c;
                    }));
                }
                setIsRenaming(false);
            })
            .catch(error => {
                console.error('Error renaming conditioning item', error);
                setIsRenaming(false);
                setIsError(true);
            });
        }
        
    };

    const cancelEntry = () => {
        setState('view');
        setIsRenaming(false);
        setConfirmModal(false);
    };
    
    if (isError) return <ErrorMessage handleConfirm={() => setIsError(false)} />;

    return (<>
        {tabValue !== 0 && 
            <IconButton style={{position: 'absolute', top: '35px', right: '0', padding: '0', fontSize: "40px", color: "var(--site-light-text-color)"}} 
                aria-label="back" size="large" alt='back'
                onClick={() => setState(tabMap[tabValue])}
            >
                <AddIcon fontSize="inherit" />
            </IconButton>
        }

        <Box sx={{ width: '100%', borderRadius: '10px', borderBottom: 1, borderColor: 'divider', overflow:'hidden' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
                <Tab label="Exercises" sx={{width: '25%', fontSize: '1.4rem'}} {...a11yProps(0)}  />
                <Tab label="Custom" sx={{width: '25%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                <Tab label="Circuits" sx={{width: '25%', fontSize: '1.4rem'}} {...a11yProps(2)}  />
                <Tab label="Tests" sx={{width: '25%', fontSize: '1.4rem'}} {...a11yProps(3)} />
            </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
            <div className='entries-in-tabs'>
                {exercises?.filter(ex => ex.type === 'standard')?.map(ex => (
                    <CustomEntry key={ex.id} type={'exercises'} item={ex} />
                ))}
            </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            <div className='entries-in-tabs'>
                {exercises?.filter(ex => ex.type === 'custom')?.map(ex => (
                    <CustomEntry key={ex.id} type={'exercises'} item={ex} handleSwitch={handleSwitchChange} handleRenaming={setRenaming} handleDelete={handleConfirmRemove}  />
                ))}
            </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
            <div className='entries-in-tabs'>
                {userCircuits?.map(circuit => (
                    <CustomEntry key={circuit.id} type={'user-circuits'} item={circuit} handleSwitch={handleSwitchChange} handleRenaming={setRenaming} handleDelete={handleConfirmRemove} />
                ))}
            </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
        <div className='entries-in-tabs'>
            {userTests?.map(test => (
                    <CustomEntry key={test.test_id} type={'tests'} item={test} handleSwitch={handleSwitchChange} handleRenaming={setRenaming} handleDelete={handleConfirmRemove} />
                ))}
            </div>
        </TabPanel>

        {state === 'createCircuit' && 
            <CreateConditioningCircuit userId={userId} exerciseList={exercises} names={circuitNames} submitHandler={handleNewUserCircuit} handleCancel={cancelEntry} />
        }

        {state === 'createExercise' && 
            <CreateCustomExercise names={customExNames} handleSubmit={handleNewCustomExercise} handleCancel={cancelEntry} />
        }

        {state === 'createTest' &&
            <CreateConditioningTest userId={userId} exerciseList={exercises} names={testNames} submitHandler={handleNewTest} handleCancel={cancelEntry} />
        } 

        {isRenaming &&
            <RenameConditioningItem type={currentType} item={currentItem} names={names} handleSubmit={handleRename} handleCancel={cancelEntry} />
        } 
        { confirmModal === true && 
            <DeleteConfirmationBox message={"Are you sure you want to delete this item? If this has already been used in a session, the delete will fail."} handleConfirm={() => handleDelete(itemToDelete.type, itemToDelete.item)} handleCancel={cancelEntry}/>
        }
    </>)

};

export default ExerciseManager;