import { useEffect, useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { IconButton, Box } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingMessage from '../../../../components/LoadingMessage';
import TestSessionExerciseEntry from './TestSessionExerciseEntry';
import Header from '../../../../components/general/Header.tsx';
import ErrorMessage from "../../../../components/ErrorMessage.js";

const ConditioningTestSession = ({ user }) => {

    const userId = user.userId;
    const navigate = useNavigate();
    const location = useLocation();
    const session = location.state?.session ?? '';

    useEffect(() => {
        if (!location.state?.session) {
            navigate('/conditioning');
        }
    }, [location.state, navigate]);
    
    const [testExercises, setTestExercises] = useState([]);
    const [sessionExercises, setSessionExercises] = useState([]);
    const [conditioningExercises, setConditioningExercises] = useState([]);
    const [state, setState] = useState('loading');    
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
    }, []);

    useEffect(() => {
        if (isTokenReady) {
            axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/tests/exercises/${session.test_id}`)
            .then(response => {
                const data = response.data;
                if (data !== null) { 
                    setTestExercises(data);
                }
            })
            .catch(error => {
                console.error('Error fetching test exercises:', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/tests/${session.user_id}/${session.id}`)
            .then(response => {
                const data = response.data;
                if (data !== null) { 
                    setSessionExercises(data);
                }
            })
            .catch(error => {
                console.error('Error fetching session exercises:', error)
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/exercises/${session.user_id}`)
            .then(response => {
                const data = response.data;
                if (data !== null) { 
                    setConditioningExercises(data);
                }
            })
            .catch(error => {
                console.error('Error fetching session exercises:', error);
                setIsError(true);
            });

            setState('view');
        }
    }, [session, isTokenReady]);

    const handleSetExerciseValue = (val, ex_id) => {
        const newExerciseWithIDs = {
            test_id: session.test_id,
            test_exercise_id: ex_id,
            value: val,
            user_id: userId,
            session_id: session.id
        }
        axios.put(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/tests/exercises`, newExerciseWithIDs, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(() => {
            setSessionExercises(sessionExercises.map(entry => {
                if (entry.test_exercise_id === newExerciseWithIDs.test_exercise_id) {
                    return newExerciseWithIDs
                }
                return entry
            }));
            setState('view');
        })
        .catch(error => {
            console.error(`Error updating circuit entry details:`, error);
            setIsError(true);
        }); 
    };
    
    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;

    if (state === 'loading' || !conditioningExercises?.length || !testExercises?.length) {
        return <LoadingMessage title={''} />
    }

    return (
        <>
            {state === 'view' && <>

                <Box sx={{ fontSize: '40px', textAlign: 'center', height: '60px', position: 'fixed', top: '18px', left: '50%', transform: 'translateX(-50%)', width: 'calc(100% - 40px)' }}>
                    <Link to="/conditioning">
                        <IconButton style={{position: 'absolute', top: '15px', left: '0', padding: '0', fontSize: "40px", color: "var(--site-light-text-color)"}} aria-label="back" size="large" alt='back' >
                            <ArrowBackIcon fontSize="inherit" />
                        </IconButton>
                    </Link>
                </Box>
                <Header title={'Test'} />
            
                <div className='entries'>
                    {sessionExercises?.map((ex) => (
                        <TestSessionExerciseEntry key={ex.test_exercise_id} sessionExercise={ex} testExercises={testExercises} exercises={conditioningExercises} handleValueChange={handleSetExerciseValue} />
                    ))} 
                </div>
            </>}
            
        </>
    )
}

export default ConditioningTestSession;