import { Link } from "react-router-dom";

const UserHome = ({user}) => {

    return (
        <>
            <div className={"img-container"}>
                <img src="../img/new-logo-s.jpg" alt="" />
            </div>
            <h3 style={{margin: "5% 0", color: "var(--site-light-text-color)"}}>Welcome, {user.username}</h3>
            <main className="menu-grid">
                <div className={"row"}>
                    <Link to="/diary"><div className={"menu-btn"}><p>Diary</p></div></Link>
                    <Link to="/events"><div className={"menu-btn"}><p>Results</p></div></Link>
                    <Link to="/goals"><div className={"menu-btn"}><p>Goals</p></div></Link>
                </div>
                <div className={"row"}>
                    <Link to="/routines"><div className={"menu-btn"}><p>Routines</p></div></Link>
                    <Link to="/drills"><div className={"menu-btn"}><p>Drills</p></div></Link>  
                    <Link to="/skills"><div className={"menu-btn"}><p>Skills</p></div></Link>
                </div>
                <div className={"row"}>
                    <Link to="/conditioning"><div className={"menu-btn"}><p>Conditioning</p></div></Link>                   
                    <Link to="/data"><div className={"menu-btn"}><p>Insights</p></div></Link>
                    <Link to="/resources"><div className={"menu-btn"}><p>Resources</p></div></Link>
                </div>
            </main>
        </>
    )
  };
  
  export default UserHome;