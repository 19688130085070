import { useState } from 'react';
import { IconButton, Button, TableCell, TableHead, TableRow, TableBody, Table, Paper, TableContainer } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'

const ConditioningEntry = ({ entry, circuit, exerciseList, setEditing, remove }) => {
    const [state, setState] = useState(false);

    const handleDeleteClick = () => {
        remove(entry.id);
    };

    const handleButtonClick = () => {
        setState(!state);
    };

    if (entry.type === 'exercise') {

        const exName = exerciseList?.find(ex => (ex.id === entry.exercise_id && ex.type === entry.exercise_type)).name;
        return (
            <div className='entry' style={{position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
                <IconButton style={{cursor: 'pointer', position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={handleDeleteClick}>
                    <DeleteIcon color="black" fontSize="inherit" />
                </IconButton>
                <IconButton style={{cursor: 'pointer', position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing(entry)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>

                <h3>{exName}</h3>
                <p>Reps: {entry.reps}</p>
                {entry.duration > 0 && <p>Duration: {entry.duration}s</p>}
                {entry.weight > 0 && <p>Weight: {entry.weight}kg</p>}
            </div>
        )
    }
    else {
        return (
            <div className='entry' style={{position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
                <IconButton style={{cursor: 'pointer', position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={handleDeleteClick}>
                    <DeleteIcon color="black" fontSize="inherit" />
                </IconButton>
                <IconButton style={{cursor: 'pointer', position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing(entry)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>

                <h3 style={{ padding: "0 40px" }}>{circuit.title}</h3>
                <p>Sets: {entry.sets}</p>

                {state && <>
                    <TableContainer component={Paper} sx={{ marginTop: '20px', maxWidth: '400px', margin: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Exercise</TableCell>
                                    <TableCell align="center">Reps</TableCell>
                                    <TableCell align="center">Duration</TableCell>
                                    <TableCell align="center">Weight</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {circuit?.exercises?.map((cEx, index) => {
                                    const exercise = exerciseList.find(
                                        ex => ex.id === cEx.exercise_id && ex.type === cEx.exercise_type
                                    );
            
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{exercise ? exercise.name : "Unknown Exercise"}</TableCell>
                                            <TableCell align="center">{cEx.reps || '-'}</TableCell>
                                            <TableCell align="center">{cEx.duration ? `${cEx.duration} secs` : '-'}</TableCell>
                                            <TableCell align="center">{cEx.weight ? `${cEx.weight} kg` : '-'}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}

                <Button style={{cursor: 'pointer', fontSize: '14px', color: '#000', backgroundColor: 'transparent'}} onClick={handleButtonClick}>
                    {!state ? 'Show exercises' : 'Hide exercises'}
                </Button>
                    
            </div>
        )
    }
};

export default ConditioningEntry;