import { useState, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup, TextField, FormControl, InputLabel, Select, MenuItem, Button, Autocomplete } from "@mui/material";

const AddDmtEntry = ({ objectives, entryHolder, handleNewEntry, handleCancel, isDisabled }) => {

    // Initialise the entry, be it blank or editing an existing one.
    const [entry, setEntry] = useState(entryHolder); 
    const [customSkills, setCustomSkills] = useState(
        entryHolder.objective.type === 'custom'
        ? [objectives.filter(obj => obj.id === entryHolder.objective.id.split('-')[0])[0], objectives.filter(obj => obj.id === entryHolder.objective.id.split('-')[1])[0]]
        : [null,null]
    );
    
    
    const initialRecorded = [];
    entryHolder.execution > 0 && initialRecorded.push('execution');
    entryHolder.total_tof > 0 && initialRecorded.push('tof');
    entryHolder.landing_penalty !== 999 && initialRecorded.push('landing');
    const [wasRecorded, setWasRecorded] = useState(initialRecorded);   
    const [isBothRecorded, setBothRecorded] = useState(wasRecorded.includes('landing') && wasRecorded.includes('execution'));
    const [leftInputStyle, setLeftInputStyle] = useState(isBothRecorded ? {width: "49.5%", margin: "10px 1% 0 0"} : {width: "100%", marginTop: "10px"});
    const [rightInputStyle, setRightInputStyle] = useState(isBothRecorded ? {width: "49.5%", marginTop: "10px"} : {width: "100%", marginTop: "10px"});

    // filter list of all objectives by type for current entry.
    const objectiveMap = entry.objective.type === 'pass' ? 'pass' : 'skill';
    const [typedObjectives, setTypedObjectives] = useState(objectives.filter((objective) => (objective.type === objectiveMap)));
    
    // Was this a single attempt or multiple
    const [singleOrMultiple, setSingleOrMultiple] = useState(entryHolder.repetitions === 1 ? 'single' : 'multiple');

    useEffect(() => {
        if (entry.objective.type === 'pass') {
            setTypedObjectives(objectives.filter((objective) => (objective.type === 'pass')));
        } 
        else if (entry.objective.type === 'mount') {
            setTypedObjectives(objectives.filter((objective) => (objective.type === 'skill' && objective.direction !== 'backwards')));
        } 
        else {
            setTypedObjectives(objectives.filter((objective) => (objective.type === 'skill')));
        }
    }, [entry.objective.type, typedObjectives, objectives]);

    useEffect(() => {
        setBothRecorded(wasRecorded.includes('landing') && wasRecorded.includes('execution'));
        setLeftInputStyle(isBothRecorded ? {width: "49.5%", margin: "10px 1% 0 0"} : {width: "100%", marginTop: "10px"});
        setRightInputStyle(isBothRecorded ? {width: "49.5%", marginTop: "10px"} : {width: "100%", marginTop: "10px"});
    }, [wasRecorded, isBothRecorded]);

    // Get difficulty of any objective
    const getDifficulty = () => {
        if (entry.completed) {
            if (entry.objective.type !== 'custom') {
                return objectives.filter(obj => obj.id === entry.objective.id)[0].difficulty;
            }
            else {
                return parseFloat(customSkills[0].difficulty + customSkills[1].difficulty);
            }
        }
        else {
            return getCompletedSkillsDifficulty(entry.objective.id, entry.total_completed_skills);
        }
        
    };

    // Get total dd when not all skills completed
    const getCompletedSkillsDifficulty = (id, completed) => {
        if (completed === 0) {
            return 0.0;
        }
        // If not complete or 0 then must be first skill only
        const skillCompleted = String(id.split('-').map(Number)[0]);

        return parseFloat(objectives.filter((value) => value.id === skillCompleted)[0].difficulty.toFixed(1));
    };

    const getTotalSkillsFromObjectiveType = (type) => {
        if (type === 'pass' || type === 'custom') return 2
        return 1
    };

    // When entry type changes, change list of objectives and update objectives property
    const handleEntryTypeChange = (e) => {

        // Change from pass to skill etc. Reset complete/total skills
        const totSkills = getTotalSkillsFromObjectiveType(e.target.value);
        const reps = entry.repetitions > 1 ? entry.repetitions : 1;
        setEntry({
            ...entry,
            objective: {type: e.target.value, id: '', name: ''},
            completed: true,
            total_skills: totSkills * reps,
            total_completed_skills: totSkills * reps
            // total_load: 0, // try move to submit
            // total_difficulty: 0 // try move to submit

        })
    };

    const handleObjectiveChange = (e, newValue) => {  
        // Same objective type so leave complete stat as was 
        
        // const reps = entry.repetitions > 1 ? entry.repetitions : 1;
        if (newValue !== null && newValue.length !== 0) {
            setEntry((entry) => ({ 
                ...entry, 
                objective: {type: entry.objective.type, id: newValue.id, name: newValue.name}
                // total_completed_skills: entry.total_skills,
                // completed: true,
                // total_difficulty: newValue.difficulty, // try move to submit
                // total_load: (newValue.difficulty + entry.total_skills) * reps // try move to submit
            }));
        }        
    };

    const handleCustomSkillChange = (e, newSkill, index) => {
        if (index === 0) {
            setCustomSkills([newSkill, customSkills[1]]);
        }
        else {
            setCustomSkills([customSkills[0], newSkill]);
        }
    };

    const handleSingleOrMultipleChange = (e) => {
        // always use complete when doing multiple so must reset complete, total completed skills and reps
        // also reset ex, tof and landing as don't use these with multiple.
        
        const skillsPerAttempt = getTotalSkillsFromObjectiveType(entry.objective.type);
        setEntry({
            ...entry,
            repetitions: 1,
            total_completed_skills: skillsPerAttempt,
            total_skills: skillsPerAttempt,
            completed: true,
            execution: 0,
            total_tof: 0,
            landing_penalty: 999
            // total_load: parseFloat((entry.total_difficulty + skillsPerAttempt).toFixed(1)) // try move to submit
        });
        setWasRecorded([]);
        setSingleOrMultiple(e.target.value);
    }

    const handleRepetitionChange = (e) => {
        let reps = parseInt(e.target.value.trim());
        reps = isNaN(reps) ? 0 : reps;

        if (reps < 0) {
            reps = 0;
        }

        const skillsPerAttempt = getTotalSkillsFromObjectiveType(entry.objective.type)
        setEntry({
            ...entry,
            repetitions: String(reps),
            total_completed_skills: reps * skillsPerAttempt,
            total_skills: reps * skillsPerAttempt
            // total_load: parseFloat((reps * (entry.total_difficulty + skillsPerAttempt)).toFixed(1)) // try move to submit
        });
    };

    // Successful / unsuccessful toggle
    const handleCompletedChange = () => {
        // const newDifficulty = (entry.objective.type === 'pass' || entry.objective.type === 'custom') ? getCompletedSkillsDifficulty(entry.objective.id, entry.total_skills - 1) : entry.total_difficulty;
        setEntry({
            ...entry,
            completed: !entry.completed,
            total_completed_skills: !entry.completed ? entry.total_skills : entry.total_skills - 1
            // total_difficulty: parseFloat(newDifficulty), // try move to submit
            // total_load: parseFloat(newDifficulty) + entry.total_skills - 1 // try move to submit
        });
    };


    const handleCompletedSkillsChange = (e) => {
        // can change this to field change maybe?

        // const newDifficulty = getCompletedSkillsDifficulty(entry.objective.id, parseInt(e.target.value));
        setEntry({
            ...entry,
            total_completed_skills: parseInt(e.target.value)
            // total_difficulty: newDifficulty, // try move to submit
            // total_load: parseInt(e.target.value) + newDifficulty // try move to submit
        });
    };

    // Tof, Ex and HD toggles
    const handleRecordedChange = (e, newRecorded) => {
        let newEntry = {...entry};
        if (!newRecorded.includes('execution')){ // works for tof but not ex or hd
            newEntry = {
                ...newEntry,
                execution: 0
            };
        }
        if (!newRecorded.includes('landing')){
            newEntry = {
                ...newEntry,
                landing_penalty: 0
            };
        }
        if (!newRecorded.includes('tof')){
            newEntry = {
                ...newEntry,
                total_tof: 0,
                time_method: 'stopwatch'
            };
        }
        setEntry(newEntry);
        setWasRecorded(newRecorded);
    };

    // For Ex, ToF etc
    const handleFloatChange = (fieldName, e, min, max) => {
        let newVal = parseFloat(e.target.value.trim());
        newVal = isNaN(newVal) ? 0 : newVal;

        if (min !== 'none' && newVal < min) {
            newVal = min;
        } else if ( max !== 'none' && newVal > max) {
            newVal = max;
        }

        setEntry((entry) => ({ ...entry, [fieldName]: String(newVal) }));
    };

    const handleLandingChange = (e, min, max) => {
        let newVal = e.target.value === '' ? 999 : parseFloat(e.target.value);
        if (newVal !== '') {
            if (min !== 'none' && newVal < min) {
                newVal = min;
            } else if ( max !== 'none' && newVal > max && newVal !== 999) {
                newVal = max;
            }
        }
        setEntry((entry) => ({ ...entry, landing_penalty: String(newVal) }));
    };
 
    // Handle changes to both feedback and self-reflection fields
    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setEntry((entry) => ({ ...entry, [fieldName]: null }));
        } else {
            setEntry((entry) => ({ ...entry, [fieldName]: e.target.value }));
        }
    };

    const handleSubmit = () => {
        const reps = entry.repetitions < 1 ? 1 : parseInt(entry.repetitions);
        const diff = parseFloat(getDifficulty().toFixed(1));
        if (entry.objective.type === 'custom') {
            setEntry((prevEntry) => {
                const updatedEntry = {
                    ...prevEntry, 
                    objective: {
                        ...prevEntry.objective,
                        id: customSkills[0].id + '-' + customSkills[1].id,
                        name: customSkills[0].name + ' -> ' + customSkills[1].name
                    },
                    execution: parseFloat(entry.execution), 
                    total_tof: parseFloat(entry.total_tof),
                    landing_penalty: parseFloat(entry.landing_penalty),
                    repetitions: reps,
                    total_difficulty: diff,
                    total_load: parseFloat(((diff + entry.total_completed_skills) * reps).toFixed(1))
                }
    
                handleNewEntry(updatedEntry);
                return updatedEntry;
            });
        }
        else {
            setEntry((prevEntry) => {
                const updatedEntry = {
                    ...prevEntry, 
                    execution: parseFloat(entry.execution), 
                    total_tof: parseFloat(entry.total_tof), 
                    landing_penalty: parseFloat(entry.landing_penalty),
                    repetitions: reps,
                    total_difficulty: diff,
                    total_load: parseFloat(((diff + entry.total_completed_skills) * reps).toFixed(1))
                }
    
                handleNewEntry(updatedEntry);
                return updatedEntry;
            });
        }
    };
    
    return (
        <form className="new-entry">

            <FormControl fullWidth style={{marginTop: '10px'}}>
                <InputLabel id="entry-type-label">Entry Type</InputLabel>
                <Select
                    labelId="entry-type-label"
                    id="entry-type-dmt"
                    disabled={isDisabled}
                    value={entry.objective.type}
                    label="Entry Type"
                    onChange={handleEntryTypeChange}
                >                    
                    <MenuItem value={'pass'}>Pass</MenuItem>        
                    <MenuItem value={'custom'}>Custom Pass</MenuItem>
                    <MenuItem value={'mount'}>Mount</MenuItem>                    
                    <MenuItem value={'dismount'}>Dismount</MenuItem>    
                    <MenuItem value={'spotter'}>Spotter</MenuItem>      
                </Select>
            </FormControl>

            {entry.objective.type !== 'custom' &&
                <Autocomplete 
                    style={{marginTop: '10px'}} 
                    options={typedObjectives} 
                    getOptionLabel={(option) => option.name} 
                    disabled={isDisabled}
                    isOptionEqualToValue={/*(option, value) => option.id === value.id*/ () => true} //hardcoded to true temporarily
                    value={entry.objective}
                    onChange={handleObjectiveChange}
                    renderInput={(params) => (
                        <TextField {...params} label={"Search or select a " + entry.objective.type} variant="outlined" />
                    )}
                />
            }
            
            {entry.objective.type === 'custom' && <>
                {/* Custom objective textbox */}
                <Autocomplete 
                    style={{marginTop: '10px'}} 
                    options={typedObjectives} 
                    getOptionLabel={(option) => option.name || ''} 
                    disabled={isDisabled}
                    isOptionEqualToValue={/*(option, value) => option.id === value.id*/ () => true} //hardcoded to true temporarily
                    value={customSkills[0] !== null ? customSkills[0] : ''}
                    onChange={(e, newSkill) => handleCustomSkillChange(e, newSkill, 0)}
                    renderInput={(params) => (
                        <TextField {...params} label={"Search or select skill 1"} variant="outlined" />
                    )}
                />
                <Autocomplete 
                    style={{marginTop: '10px'}} 
                    options={typedObjectives} 
                    getOptionLabel={(option) => option.name || ''} 
                    isOptionEqualToValue={() => true}
                    disabled={isDisabled}
                    value={customSkills[1] !== null ? customSkills[1] : ''}
                    onChange={(e, newSkill) => handleCustomSkillChange(e, newSkill, 1)}
                    renderInput={(params) => (
                        <TextField {...params} label={"Search or select skill 2"} variant="outlined" />
                    )}
                />
                
            </>}

            {/* If single or multiple attempts */}
            <ToggleButtonGroup
                fullWidth
                style={{marginTop: '10px'}}
                color="primary"
                disabled={isDisabled}
                value={singleOrMultiple}
                exclusive
                onChange={(e) => handleSingleOrMultipleChange(e)}
                aria-label="Single attempt or multiple"
            >
                <ToggleButton color="primary" value={'single'} aria-label="single">Single Attempt</ToggleButton>
                <ToggleButton color="primary" value={'multiple'} aria-label="multiple">Multiple</ToggleButton>
            </ToggleButtonGroup>


            {singleOrMultiple === 'multiple' &&
                <TextField fullWidth style={{ marginTop: "10px" }}
                    type="number"
                    id="repetitions"
                    label="Repetitions"
                    variant="outlined"
                    disabled={isDisabled}
                    value={entry.repetitions}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => handleRepetitionChange(e)}
                />
            }

            {/* Only show these field if single attempt */}
            {singleOrMultiple === 'single' && <>
                {/* Was turn successful */}
                {(entry.objective.id !== '' && entry.objective.type !== 'custom') && <>
                    <ToggleButtonGroup
                        fullWidth
                        style={{marginTop: '10px'}}
                        color="primary"
                        disabled={isDisabled}
                        value={entry.completed}
                        exclusive
                        onChange={handleCompletedChange}
                        aria-label="routine success"
                    >
                        <ToggleButton color="success" value={true} aria-label="Complete">Complete</ToggleButton>
                        <ToggleButton color="error" value={false} aria-label="Part Complete">Part Complete</ToggleButton>
                    </ToggleButtonGroup>
                </>}

                {/* Num completed skills if unsuccessful */}
                {(entry.completed === false && entry.objective.type === 'pass') && <>
                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <InputLabel id="skills-completed-label">Skills Completed</InputLabel>
                        <Select
                            labelId="skills-completed-label"
                            label="Skills Completed"
                            variant="outlined"
                            disabled={isDisabled}
                            value={entry.total_completed_skills}
                            onChange={handleCompletedSkillsChange}
                        >
                            {[...Array(entry.total_skills).keys()].map((number) => (
                                <MenuItem key={number} value={number}>{number}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>}

                {/* Was execution, tof and landing recorded */}
                <ToggleButtonGroup
                    fullWidth
                    style={{marginTop: '10px'}}
                    color="primary"
                    disabled={isDisabled}
                    value={wasRecorded}
                    onChange={handleRecordedChange}
                    aria-label="Recorded scores"
                >
                    <ToggleButton value="execution" aria-label="execution">Execution</ToggleButton> {/* only for pass and custom? */}
                    {(entry.objective.type !== 'mount' && entry.objective.type !== 'spotter') &&
                        <ToggleButton value="landing" aria-label="landing">Landing</ToggleButton>
                    }
                    <ToggleButton value="tof" aria-label="tof">Height</ToggleButton>
                </ToggleButtonGroup>

                {/* Execution number textbox */}
                {wasRecorded.includes('execution') &&                    
                    <TextField style= {leftInputStyle}
                        type="number"
                        id="execution-number"
                        variant="outlined"
                        disabled={isDisabled}
                        label="Execution"
                        value={entry.execution}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onBlur={(e) => handleFloatChange('execution', e, 0, 20)}
                        onChange={(e) => handleFieldChange('execution', e)}
                    />
                }

                {/* Landing number textbox */} 
                {wasRecorded.includes('landing') &&                    
                    <TextField style={rightInputStyle}
                        type="number"
                        id="landing-number"
                        variant="outlined"
                        label="Landing"
                        disabled={isDisabled}
                        value={parseFloat(entry.landing_penalty) !== 999 ? entry.landing_penalty : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => handleLandingChange(e, 0, 3)}
                    />
                }

                {wasRecorded.includes('tof') && <>
                    {/* Time number textbox */} 
                    <TextField style={{ width: "49.5%", margin: "10px 1% 0 0" }}
                        type="number"
                        id="tof-number"
                        label="Time"
                        variant="outlined"
                        disabled={isDisabled}
                        value={entry.total_tof}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onBlur={(e) => handleFloatChange('total_tof', e, 0, entry.time_method === 'tof' ? 6 : 7)}
                        onChange={(e) => handleFieldChange('total_tof', e)}
                    />

                    {/* Timing method */}  
                    <FormControl style={{ width: "49.5%", marginTop: "10px" }}>
                        <InputLabel id="timing-label">Timing</InputLabel>
                        <Select
                            labelId="timing-label"
                            id="timing-select"
                            label="Method of Timing"
                            disabled={isDisabled}
                            value={entry.time_method}
                            onChange={(e) => handleFieldChange('time_method', e)}
                        >
                            <MenuItem value={'tof'}>ToF</MenuItem>
                            <MenuItem value={'stopwatch'}>Stopwatch</MenuItem>
                        </Select>
                    </FormControl>
                </>}
            </>}

            {/* Feedback textbox */}    
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                multiline
                rows={3}
                variant="outlined"
                id="feedback"
                label="Coaches Feedback"
                disabled={isDisabled}
                value={entry.feedback !== null ? entry.feedback : ''}
                onChange={(e) => handleFieldChange('feedback', e)}
            />

            {/* Self reflection textbox */}  
            <TextField fullWidth  style={{ marginTop: "10px" }}
                type="text"
                multiline
                rows={3}
                variant="outlined"
                id="reflection"
                label="Self Reflection"
                disabled={isDisabled}
                value={entry.self_reflection !== null ? entry.self_reflection : ''}
                onChange={(e) => handleFieldChange('self_reflection', e)}
            />

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={(entry.objective.type !== 'custom' && entry.objective.id === '') || (entry.objective.type === 'custom' && (customSkills[0] === null || customSkills[1] === null)) || isDisabled}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Save entry
                </Button>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>            
        </form>
    )
};

export default AddDmtEntry;