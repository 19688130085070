import { Box, CircularProgress } from "@mui/material";

const LoadingMessage = (props) => {
    return (
      <>
        <Box sx={{ fontSize: '40px', textAlign: 'center', height: '60px', color: 'var(--site-light-text-color)' }}>
            {props.title}
        </Box> 

        <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '65dvh',  // Full viewport height to center the content
        }}
      >
        <CircularProgress />
        <h3 style={{marginTop: '10px', color: 'var(--site-light-text-color)' }}>Loading...</h3>
      </Box>
      </>
    )
  };

export default LoadingMessage;