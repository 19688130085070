const RoundDownToFiveMinutes = (date) => {
    const roundedDate = new Date(date);
    const minutes = roundedDate.getMinutes();
    const roundedMinutes = Math.floor(minutes / 5) * 5;
    roundedDate.setMinutes(roundedMinutes);
    roundedDate.setSeconds(0); // Optional: reset seconds to 0
    roundedDate.setMilliseconds(0); // Optional: reset milliseconds to 0
    return roundedDate;
};

export default RoundDownToFiveMinutes;