import { useState } from "react";
import { TextField, Button } from "@mui/material";

const CreateCustomExercise = ({names, handleSubmit, handleCancel}) => {

    const [exercise, setExercise] = useState('');

    return (<>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}></div>
        
        <div className="form-wrapper" style={{backgroundColor: 'var(--site-light-background-color)', padding: '0 5px', border: "2px solid var(--site-light-background-color)", borderRadius: '15px', width: '100%', zIndex: '1000'}}>
            <h2>New Custom Exercise</h2>
            <TextField fullWidth style={{marginTop: '15px'}}
                type="text"
                id="Exercise"
                label="Exercise"
                variant="outlined"
                value={exercise}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setExercise(e.target.value)}
            />

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={() => handleSubmit(exercise)}
                    disabled={!exercise?.length || names.includes(exercise.toLowerCase())}
                    variant="contained"
                >
                    Add
                </Button>

                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </div>
        </>)
}

export default CreateCustomExercise;