import { Tab, Tabs, Box, IconButton } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Header from "../../components/general/Header.tsx";
import ExerciseManager from "./ExerciseManager.js";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";
import ConditioningSessions from "./components/standard/ConditioningSessions.js";
import ConditioningTestSessions from "./components/testing/ConditioningTestSessions.js";

const Conditioning = ({ user }) => {

    const [tabValue, setTabValue] = useState(0);
    const [exerciseManager, setExerciseManager] = useState(false);
    
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Header title={'Conditioning'} />

            <IconButton style={{position: 'absolute', top: '35px', left: '0', padding: '0', fontSize: "40px", color: "var(--site-light-text-color)"}} 
                aria-label="back" size="large" alt='back'
                onClick={() => setExerciseManager(!exerciseManager)}
            >
                <MoreVertIcon fontSize="inherit" />
            </IconButton>

            {!exerciseManager ? <>
                <Box sx={{ width: '100%', borderRadius: '10px', borderBottom: 1, borderColor: 'divider', overflow:'hidden' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
                        <Tab label="Sessions" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(0)}  />
                        <Tab label="Testing" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                
                <TabPanel value={tabValue} index={0}>
                    <ConditioningSessions user={user} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <ConditioningTestSessions user={user} />
                </TabPanel>
            </> : <>
                <ExerciseManager user={user} />
            </>}
        </>
    )

};

export default Conditioning;