import { useState } from 'react';
import { Button, TextField } from '@mui/material';

const TestSessionExerciseEntry = ({ sessionExercise, testExercises, exercises, handleValueChange }) => {

    const testExercise = testExercises.find(ex => ex.id === sessionExercise.test_exercise_id);
    const exercise = exercises.find(ex => ex.id === testExercise.exercise_id);

    const [metricValue, setMetricValue] = useState(sessionExercise.value);
    const [isDirty, setIsDirty] = useState(false);

    const handleButtonClick = () => {

        handleValueChange(metricValue, sessionExercise.test_exercise_id);
        setIsDirty(false);
    };

    const handleFloatChange = (val, min, max) => {
        let newVal = parseFloat(val.trim());
        newVal = isNaN(newVal) ? 0 : newVal;

        if (min !== 'none' && newVal < min) {
            newVal = min;
        } else if ( max !== 'none' && newVal > max) {
            newVal = max;
        }

        setMetricValue(newVal);
    };

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid var(--site-light-background-color)', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3>{exercise.name}</h3>
            <p>{testExercise.details}</p>
            <form style={{ display: 'flex', justifyContent: 'center' }}>
                <TextField fullWidth style={{ width: "100%", margin: "10px 5px" }}
                    type="number"
                    id="value"
                    label={testExercise.metric}
                    variant="outlined"
                    value={metricValue}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            fontSize: '1.4rem',
                        },
                    }}
                    onBlur={(e) => {handleFloatChange(e.target.value, 0, 'none');}}
                    onChange={(e) => {setMetricValue(e.target.value); setIsDirty(true)}}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); // Prevent default Enter key behavior (form submission)
                        }
                    }}
                />
            </form>
            
            <div style={{ textAlign: 'center' }}>
                <Button 
                    style={{cursor: 'pointer', fontSize: '12px', color: '#fff'}} 
                    onClick={handleButtonClick}
                    variant='contained'
                    disabled={!isDirty}
                >
                    Save
                </Button>
            </div>
        </div>
    );
    
};

export default TestSessionExerciseEntry;