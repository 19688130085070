import PropTypes from 'prop-types';
import { Box } from "@mui/material";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

// a11yProps function
export const a11yProps = (index) => {
  return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
  };
};

export default TabPanel;



