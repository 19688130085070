import { useEffect, useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Fab, Box, CircularProgress } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
import SkillBrick from "./SkillBrick";
import NewSkillFocus from './NewSkillFocus';
import AddNewSkill from './AddNewSkill';
import ErrorMessage from "../../../components/ErrorMessage.js";


const NewSkills = ({skills, user}) => {

    const userId = user.userId;
    const [newSkillsList, setNewSkillsList] = useState([]);
    const [focusSkill, setFocusSkill] = useState(null);
    const [state, setState] = useState('loading');
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
      }, []);

    useEffect(() => {
        if (isTokenReady) {
            axios.get(process.env.REACT_APP_API_HOST + `/api/new-skills/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setNewSkillsList(data);
                }
                setState('view');
            })
            .catch(error => {
                console.error('Error fetching user new skills:', error);
                setIsError(true);
            });
        }
    }, [userId, isTokenReady]);

    const handleFocus = (newSkill) => {
        setFocusSkill(newSkill);
        setState('focus');
    };

    const handleNew = (newSkill) => {
        const newSkillWithUser = {...newSkill, user_id: userId};

        axios.post(process.env.REACT_APP_API_HOST + '/api/new-skills', newSkillWithUser, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(() => {
            setNewSkillsList([newSkillWithUser, ...newSkillsList]);
            handleFocus(newSkillWithUser);
        })
        .catch(error => {
            console.error('Error posting user new skill:', error);
            setIsError(true);
        });  
    };

    const handleUpdate = (skillProgress) => {        

        axios.post(process.env.REACT_APP_API_HOST + '/api/new-skills', skillProgress, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(() => {
            setNewSkillsList(newSkillsList.map(skill => {
                if (skill.skill_id === focusSkill.skill_id) {
                    return skillProgress;
                }
                else {
                    return skill;
                }
            }));
            setState('view');
        })
        .catch(error => {
            console.error('Error updating user new skill:', error);
            setIsError(true);
        });  
    };

    const handleExit = () => {
        setState('view');
    };

    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;

    if (state === 'loading') {
        return (
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '65dvh',
                }}
            >
            <CircularProgress />
                <h3 style={{marginTop: '10px' }}>Loading...</h3>
            </Box>
        );
    }

    return (
        <>
            <div className='entries-in-tabs'>
                {newSkillsList?.map(skill => (
                    <SkillBrick className='entry' key={skill.skill_id} playSkill={skill} handleFocus={handleFocus} />
                ))}
            </div>

            {state === 'focus' && 
                <NewSkillFocus newSkill={focusSkill} handleUpdate={handleUpdate} handleExit={handleExit} />
            }

            {state === 'entering' &&
                <AddNewSkill skills={skills.filter(skill =>
                    !newSkillsList.some(newSkill => newSkill.skill_id === skill.id)
                  )} handleAdd={handleNew} handleExit={handleExit} />
            }

            {state === 'view' &&
                <Fab color="secondary" aria-label="add" onClick={() => {setState('entering')}} style={{ position: 'absolute', bottom: '20px', right: '0' }}>
                    <AddIcon sx={{ fontSize: '3rem' }} />
                </Fab>
            }
        </>
    )
};

export default NewSkills;