import { Button, Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

const AddConditioningExercise = ({ exerciseList, handleSubmit, handleCancel}) => {

    const [exercise, setExercise] = useState({exercise_id: 1, exercise_type: 'standard', reps: 1, duration: 0, weight: 0});

    const handleExerciseChange = (e, newValue) => {
        newValue !== null &&
            setExercise({
                ...exercise,
                exercise_id: newValue.id,
                exercise_type: newValue.type
            });
    }

    const handleSetsChange = (reps) => {
        setExercise({
            ...exercise,
            reps: reps
        })
    };

    const handleFloatChange = (fieldName, e, min, max) => {
        let newVal = parseFloat(e.target.value.trim());
        newVal = isNaN(newVal) ? 0 : newVal;

        if (min !== 'none' && newVal < min) {
            newVal = min;
        } else if ( max !== 'none' && newVal > max) {
            newVal = max;
        }

        setExercise((entry) => ({ ...entry, [fieldName]: String(newVal) }));
    };

    const handleFieldChange = (fieldName, e) => {

        if (e.target.value === '') {
            setExercise({ ...exercise, [fieldName]: '' });
        } else {
            setExercise({ ...exercise, [fieldName]: e.target.value });
        }
    };

    const preSubmit = () => {
        setExercise((prev) => {
            const finalEx = {
                ...exercise,
                duration: parseFloat(prev.duration),
                weight: parseFloat(prev.weight)
            }

            handleSubmit(finalEx);
            return finalEx
        });

    }
    
    return (
        <div style={{backgroundColor: 'var(--site-light-background-color)', padding: '0 5px', border: "2px solid grey", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000'}}>
            <h2>Add Exercise</h2>

            <Autocomplete style={{marginTop: '10px'}} 
                options={exerciseList} 
                getOptionLabel={option => option.name} 
                isOptionEqualToValue={() => true}
                value={exerciseList.filter(ex => (ex.id === exercise.exercise_id && ex.type === exercise.exercise_type))[0]}
                onChange={handleExerciseChange}
                renderInput={(params) => (
                    <TextField {...params} 
                        label={"Search or select an exercise"} 
                        variant="outlined" 
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                fontSize: '1.4rem',
                            },
                        }}
                    />
                )}
            />

            <div className="hydration-buttons" style={{margin: '5% 20% 5% 5%'}}>
                <p>Reps: </p>
                <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => handleSetsChange(exercise.reps > 1 ? exercise.reps - 1 : 1)}>-</Button>
                <p>{exercise.reps}</p>
                <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => handleSetsChange(exercise.reps + 1)}>+</Button>
            </div>
            
            <div className="hydration-buttons" style={{gap: '10px'}}>
                <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => handleSetsChange(exercise.reps + 5)}>+5</Button>
                <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => handleSetsChange(exercise.reps + 10)}>+10</Button>
                <Button variant="outlined" size="medium" className="add-subtract-button" onClick={() => handleSetsChange(1)}>reset</Button>
            </div>

            <TextField fullWidth style={{marginTop: '15px'}}
                type="number"
                id="weight"
                label="Weight"
                variant="outlined"
                value={exercise.weight}
                InputLabelProps={{
                    shrink: true,
                    style: {
                        fontSize: '1.4rem',
                    },
                }}
                onBlur={(e) => handleFloatChange('weight', e, 0, 'none')}
                onChange={(e) => handleFieldChange('weight', e)}
            />

            {exercise.reps === 1 && 
                <TextField fullWidth style={{marginTop: '15px'}}
                    type="number"
                    id="duration"
                    label="Duration"
                    variant="outlined"
                    value={exercise.duration}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            fontSize: '1.4rem',
                        },
                    }}
                    onBlur={(e) => handleFloatChange('duration', e, 0, 'none')}
                    onChange={(e) => handleFieldChange('duration', e)}
                />
            }
            

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={preSubmit}
                    variant="contained"
                >
                    Add
                </Button>

                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default AddConditioningExercise;